// import process from 'pages/analytics/process/locale/pt_BR'

const localeValues = {
  locale: 'pt-br',
  global: {
    request: {
      put: {
        code: {
          200: 'Registro editado com sucesso',
          401: 'Sem Autorização, por favor faça login novamente',
          409: 'Não é possível editar este registro pois já está relacionado a outros dados no sistema. Por favor, revise e ajuste os vínculos antes de tentar novamente.',
          500: 'Erro ao tentar editar este registro.',
        },
      },
      post: {
        code: {
          200: 'Registro inserido com sucesso',
          401: 'Sem Autorização, por favor faça login novamente',
          409: 'Este registro não pode ser criado pois está conflitando com um registro já existente no sistema. Por favor, verifique e corrija os dados antes de tentar novamente.',
          500: 'Erro ao tentar salvar este registro.',
        },
      },
      delete: {
        code: {
          200: 'Registro deletado com sucesso',
          401: 'Sem Autorização, por favor faça login novamente',
          409: 'Não é possível excluir este registro pois está relacionado a outros dados no sistema. Antes de excluí-lo, remova quaisquer vínculos existentes com este registro.',
          500: 'Erro ao tentar deletar este registro.',
        },
      },
      get: {
        code: {
          401: 'Sem Autorização, por favor faça login novamente',
          500: 'Erro ao buscar registros.',
        },
      },
      error: {
        unknow: 'Erro de conexão. Por favor, tente novamente mais tarde.',
      },
    },
    error: {
      messages: {
        serverError: 'Erro na conexão com o servidor',
        defaultError: 'Houve algum problema, por favor, tente novamente',
        loadDataError: 'Erro ao carregar os dados, por favor, tente novamente',
        equipmentError: 'Erro ao tentar consultar os dados de equipamentos',
        reportError: 'Erro ao tentar consultar os dados de boletim',
        reconciliationError: 'Erro ao tentar realizar a reconciliação',
        sendError:
          'Houve um problema ao enviar os dados, por favor, tente novamente',
        deleteError:
          'Houve um erro ao tentar deletar os dados, por favor, tente novamente',
        syntaxError: 'Texto sem dados, por favor, verifique a sintaxe',
        calculationError: 'Verifique a sintaxe',
        pdfError:
          'Houve um problema ao tentar exportar o PDF, por favor tente novamente',
        errorImportingFile:
          'Houve um error na importação, por favor, revise o arquivo e tente novamente',
        imageError: 'O arquivo inserido não é uma imagem.',
        attachmentError:
          'Erro ao tentar adicionar anexo, por favor, tente novamente',
      },
    },
    info: {
      downsamplePoints:
        'Dados com amostragem reduzida. Para consultar todos os pontos, diminua o período',
      noPermission: 'Sem permissão',
      dividerFormAddButton:
        'A última parada precisa ter um tempo maior que 1 segundo!',
      diffIndicatorsValues:
        'Configurações de indicadores divergentes, utilizado visualização do indicador de sequência 1',
      blankToAutoGenerated: 'Deixe em branco para gerar automaticamente',
      noPermissionForItem: 'Você não possui permissão para acessar esse item',
      loadingComponents:
        'Há componentes sendo carregados nesta aba ou nas demais abas.',
      inactiveUser: 'Usuário inativo',
    },
    success: {
      messages: {
        download: 'Download realizado com sucesso',
        registration: 'Cadastro realizado com sucesso',
        update: 'Alteração realizada com sucesso',
        delete: 'Exclusão realizada com sucesso',
        activate: 'Grupo de tags ativado com sucesso',
        deActivate: 'Grupo de tags desativado com sucesso',
        save: 'Salvo com sucesso',
        reconciliation: 'Reconciliação realizada com sucesso',
        integrated: 'Integração realizada com sucesso',
        import: 'Importação realizada com sucesso',
        attachment: 'Anexo adicionado com sucesso',
        attachmentRefused: 'Extensão do anexo não permitida',
      },
    },
    warning: {
      messages: {
        pointsQuantity:
          'Quantidade de pontos para as tags são diferentes, tente agrupar para realizar a análise',
        pointsQuantityDifferent:
          'Quantidade de pontos para as tags são diferentes',
        pointsInsufficient:
          'Quantidade de pontos insuficiente para realizar o cálculo',
        noAppointments: 'Sem apontamentos para o dia',
        registerTagLimit: 'Limite de tags atingido',
        noComponentType: 'Componente inválido:',
        searchNoGroupBy:
          'Pesquisa sem agrupamento, Tags que não possuem pontos não apareceram para visualização.',
      },
    },
    loading: {
      messages: {
        downloading: 'Preparando arquivo para download...',
        saving: 'Salvando...',
        loading: 'Carregando...',
        finished: 'Finalizado',
      },
    },
    labels: {
      selectProductionOrder: 'Selecione a ordem de produção',
      selectShift: 'Selecione o turno',
      deletedGroup: 'Grupo excluído',
      confirmText: 'Confirmar',
      between: 'entre',
      underOf: 'abaixo de',
      aboveOf: 'acima de',
      selectUsers: 'Selecione os usuários',
      available: 'Disponível',
      used: 'Utilizado',
      stock: 'Estoque',
      qrCode: 'QR Code',
      operators: 'Operadores',
      createdAt: 'Criado em',
      updatedAt: 'Atualizado em',
      time: 'Tempo',
      permissions: 'Permissões',
      color: 'Cor',
      placeholder: 'Por favor, escolha',
      code: 'Código',
      codeExists: 'Código já existe',
      action: 'Ação',
      actions: 'Ações',
      configuration: 'Configuração',
      exitConfiguration: 'Sair da configuração',
      deleteConfirmation: 'Tem certeza que deseja excluir o item selecionado?',
      cancelConfirmation: 'Tem certeza que deseja cancelar?',
      notAllowConfirmation: 'Tem certeza que não deseja permitir?',
      revisionConfirmation: 'Tem certeza que deseja criar uma nova revisão?',
      revisionChecklistConfirmation:
        'Para prosseguir é necessário preencher uma observação!',
      typeObservation: 'Digite uma observação',
      revisionInserted: 'Revisão inserida com sucesso',
      revision: 'Revisão',
      notFound: 'Desculpe, a página visitada não existe',
      yes: 'Sim',
      no: 'Não',
      changeForm: 'Formulário de alteração',
      registrationForm: 'Formulário de cadastro',
      close: 'Fechar',
      lot: 'Lote',
      description: 'Descrição',
      recents: 'Recentes',
      undefined: 'Indefinido',
      dateStart: 'Data Inicial',
      dateEnd: 'Data Final',
      tag: 'Tag',
      tags: 'Tags',
      more: 'Mais',
      upload: 'Upload',
      profile: 'Perfil',
      period: 'Período',
      periods: 'Períodos',
      type: 'Tipo',
      types: 'Tipos',
      group: 'Grupo',
      groups: 'Grupos',
      all: 'Todos',
      message: 'Mensagem',
      date: 'Data',
      user: 'Usuário',
      userExists: 'Usuário já existe',
      users: 'Usuários',
      userGroups: 'Grupo de Recursos',
      password: 'Senha',
      form: 'Formulário',
      notes: 'Notas',
      limit: 'Limite',
      limits: 'Limites',
      value: 'Valor',
      unt: 'und',
      general: 'Geral',
      generals: 'Gerais',
      name: 'Nome',
      auto: 'Automático',
      manual: 'Manual',
      atributes: 'Atributos',
      standardDeviation: 'Desvio padrão',
      average: 'Média',
      variance: 'Variância',
      better: 'Melhor',
      worst: 'Pior',
      groupBy: 'Agrupar',
      noGroupBy: 'Sem agrupamento',
      hour: 'Hora',
      shift: 'Turno',
      shiftGrouped: 'Turno - Agrupado',
      day: 'Dia',
      week: 'Semana',
      month: 'Mês',
      year: 'Ano',
      custom: 'Personalizado',
      vertical: 'Vertical',
      order: 'Ordenação',
      noOrder: 'Sem ordenação',
      ascendingOrder: 'Ordem crescente',
      descendingOrder: 'Ordem decrescente',
      horizontal: 'Horizontal',
      total: 'Total',
      items: 'Itens',
      editMode: 'Modo edição',
      chart: 'Gráfico',
      boxplot: 'BoxPlot',
      selectTagsChart: 'Selecione tags para gerar o gráfico',
      selectFramesChart: 'Selecione eventos para gerar o gráfico',
      pValue: 'Valor-p',
      quantity: 'Quantidade',
      difference: 'Diferença',
      upperQuartile: 'Quartil superior',
      lowerQuartile: 'Quartil inferior',
      maximum: 'Máximo',
      minimum: 'Mínimo',
      median: 'Mediana',
      sum: 'Soma',
      options: 'Opções',
      enable: 'Habilitar',
      disable: 'Desabilitar',
      enablePointsInChart: 'Habilitar uso do ponto no gráfico e cálculos',
      disablePointsInChart: 'Desabilitar uso do ponto no gráfico e cálculos',
      needToBeInEditionMode:
        'É necessário entrar em modo de edição para alterar o status',
      data: 'Dados',
      motive: 'Motivo',
      motives: 'Motivos',
      accumulated: 'Acumulado',
      event: 'Evento',
      start: 'Início',
      end: 'Fim',
      status: 'Status',
      settings: 'Ajustes',
      observation: 'Observação',
      noObservation: 'Não há observação',
      criticality: 'Criticidade',
      equipment: 'Equipamento',
      element: 'Elemento',
      requiredField: 'Este campo é obrigatório',
      text: 'Texto',
      texts: 'Textos',
      outEspecificationLimit: 'Fora do limite de especificação',
      outValidLimit: 'Fora do limite válido',
      noData: 'Sem dados',
      withData: 'Com dados',
      system: 'Sistema',
      unit: 'Unidade',
      units: 'Unidades',
      allUnits: 'Todas as unidades',
      allUsers: 'Todos os usuários',
      allTags: 'Todas as tags',
      allInstruments: 'Todos os instrumentos',
      allElements: 'Todos os elementos',
      other: 'Outro',
      list: 'Lista',
      lists: 'Listas',
      details: 'Detalhes',
      filter: 'Filtro',
      inProduction: 'Em produção',
      concluded: 'Concluído',
      selectDate: 'Selecione uma data',
      upperLimit: 'Limite superior',
      lowerLimit: 'Limite inferior',
      search: 'Buscar',
      lsc: 'Limite superior de controle',
      lc: 'Linha central',
      lic: 'Limite inferior de controle',
      hideYAxis: 'Esconder eixo y',
      showYAxis: 'Exibir eixo y',
      showXAxis: 'Exibir eixo x',
      hideValues: 'Esconder valores',
      showValues: 'Exibir valores',
      sequence: 'Sequência',
      line: 'Linha',
      column: 'Coluna',
      onlyLessThan500Points: 'Apenas quando menor que 500 pontos',
      min: 'Min',
      max: 'Max',
      amplitude: 'Amplitude',
      select: 'Selecione',
      closedEvent: 'Evento fechado',
      openEvent: 'Evento aberto',
      frame: 'Evento',
      frames: 'Eventos',
      information: 'Informação',
      conditions: 'Condições',
      count: 'Quantidade de pontos',
      durationFrame: 'Duração',
      lastValue: 'Último valor',
      firstValue: 'Primeiro valor',
      mean: 'Média',
      title: 'Título',
      result: 'Resultado',
      key: 'Chave',
      operator: 'Operador',
      maxValue: 'Valor máximo',
      minValue: 'Valor mínimo',
      centralValue: 'Valor central',
      sumValues: 'Soma dos valores',
      tagNotFound: 'Tag não encontrada',
      or: 'OU',
      and: 'E',
      component: 'Componente',
      display: 'Exibição',
      advanced: 'Avançado',
      visualization: 'Visualização ',
      fields: 'Campos',
      annul: 'Anular',
      default: 'Padrão',
      automaticAppointment: 'Apontamento automático',
      tagValue: 'Valor da tag',
      automaticAppointmentTag: 'Tag para apontamento automático',
      referenceValue: 'Valor de referência',
      alarmsReferenceValue: 'Valor de alarme inativo',
      activeTag: 'Tag ativa',
      now: 'Agora',
      attachments: 'Anexos',
      file: 'Arquivo',
      noUser: 'Nenhum usuário',
      os: 'O.S.',
      height: 'Altura',
      width: 'Largura',
      displayNoData: 'Exibir "sem dados"',
      sector: 'Setor',
      confirm: 'Confirme',
      currentDate: 'Data atual',
      item: 'Item',
      expandAll: 'Expandir todos',
      minute: 'Minuto',
      bold: 'Negrito',
      italic: 'Itálico',
      underline: 'Sublinhado',
      textColor: 'Cor',
      separator: 'Separador',
      link: 'Link',
      fontSize: 'Tamanho da fonte',
      why: 'Porque',
      what: 'O que',
      how: 'Como',
      isActive: 'Ativo',
      expiresIn: 'Expira em',
      batch: 'Batelada',
      noTemplates:
        'Não há templates cadastrados, o PDF será exportado no modelo padrão',
      reverse: 'Estornar',
      reversed: 'Estornado',
      toggleReverse: 'Inverter ordenação',
      images: 'Imagens',
      registeredQuantity: 'Quantidade registrada',
      tagCode: 'Código da tag',
      tagDescription: 'Descrição da tag',
      frequency: 'Frequência',
      quantityNotallowed: 'Quantidade maior que a permitida',
      request: 'Requisição',
      justification: 'Justificativa',
      justificationPlaceholder: 'Preencha a justificativa para prosseguir',
      noPoints: 'Não há pontos para o período selecionado',
    },
    button: {
      insertTags: 'Inserir tags',
      settings: 'Ajustes',
      annulPoint: 'Anular Parada',
      deActivate: 'Desativar',
      activate: 'Ativar',
      enablePoint: 'Habilitar Parada',
      splitPoint: 'Dividir parada',
      historic: 'Histórico',
      delete: 'Deletar',
      edit: 'Editar',
      process: 'Processar',
      show: 'Visualizar',
      update: 'Atualizar',
      insert: 'Inserir',
      cancel: 'Cancelar',
      save: 'Salvar',
      saveAndGo: 'Salvar e abrir',
      comment: 'Comentar',
      change: 'Alterar',
      backToInitialPage: 'Voltar a página inicial',
      back: 'Voltar',
      insertNew: 'Inserir novo',
      registerNew: 'Cadastrar novo',
      enter: 'Entrar',
      export: 'Exportar',
      import: 'Importar',
      addPeriod: 'Adicionar período',
      remove: 'Remover',
      add: 'Adicionar',
      approve: 'Aprovar',
      notApprove: 'Não aprovar',
      reprove: 'Reprovar',
      integrate: 'Integrar',
      reconciliate: 'Reconciliar',
      download: 'Baixar',
      validateKey: 'Validar chave',
      cleanData: 'Limpar dados',
      exclude: 'Excluir',
      addItem: 'Adicionar item',
      simulate: 'Simular',
      changeScales: 'Alterar escalas',
      reset: 'Resetar',
      filter: 'Filtrar',
      saveChanges: 'Salvar alterações',
      ok: 'Ok',
      execute: 'Executar',
      qrCode: 'Código QR',
      addAttachment: 'Adicionar anexo',
      clearAppointments: 'Limpar apontamentos',
      allow: 'Permitir',
      notAllow: 'Não permitir',
      create: 'Criar',
      language: 'pt-br',
      newRevision: 'Nova revisão',
      showPdf: 'Visualizar PDF',
      orientation: 'Orientação',
      portrait: 'Retrato',
      landscape: 'Paisagem',
      discardChanges: 'Descartar alterações',
      viewQrCode: 'Visualizar QR Code',
      print: 'Imprimir',
      archive: 'Arquivar',
      unarchive: 'Desarquivar',
    },
    fcm: {
      countChildrenNotBool: 'Parâmetro inválido (countChildren)',
      qtdGatilhosGPA: {
        notTagGroup: 'Informe o grupo de tags',
        tagNotInGroupOrElement:
          'A tag não pertence ao grupo ou ao elemento informado',
        emptyTagGroup:
          'O grupo de tags informado não existe ou não possui tags',
      },
      actionPlanTime: {
        noTriggersAvailable:
          'Não há gatilhos disponíveis para este p_pltCode neste período',
        noPlansApproval:
          'Não há planos enviados para aprovação para este p_pltCode neste período',
        noCompletedActions:
          'Não há ações concluídas para os planos deste p_pltCode neste período',
        noActionsCompletedForPlan: 'Não há ações concluídas para este plano',
      },
      qtyAlarms: {
        notTagGroup: 'Informe o grupo de tags',
      },
      qtdOs: {
        noStatus: 'Verifique a existência do status',
        noOsType: 'Verifique a existência do tipo da OS',
        noUserGroup: 'Verifique a existência do grupo de recursos',
        noElement: 'Verifique a existência do elemento',
        showType:
          'parâmetro show aceita somente hora (hour) ou quantidade (qty)',
      },
      qtdPreventiveOs: {
        noStatus:
          'parâmetro status aceita apenas (initial) ou (inProgress) ou (waitingApproval) ou (finished)',
      },
    },
  },
  menu: {
    dashboard: {
      title: 'Dashboard',
    },
    analytics: {
      title: 'Análise de Dados',
      submenu: {
        analysisProcess: 'Análise de Processos',
        eventManager: 'Gestão de Eventos',
        correlation: 'Correlação',
        operator: 'Análise de  Operador',
        comparativeBetweenPeriods: 'Comparativo Entre Períodos',
        reportPerPeriods: 'Reporte por Períodos',
      },
    },
    dataMining: {
      title: 'Mineração de Dados',
      submenu: {
        regression: 'Regressão',
        clustering: 'Clusterização',
      },
    },
    controlLoopManagement: {
      title: 'Malha de Controle',
      submenu: {
        performance: 'Desempenho',
        overview: 'Saúde de Malhas',
      },
    },
    statisticalControl: {
      title: 'Controle Estatístico',
      submenu: {
        exploratory: 'Exploratório',
        production: 'Produção',
        management: 'Gestão de Desvios',
      },
    },
    rootCause: {
      title: 'Análise de Causa Raiz',
      submenu: {
        actionsAndPlans: 'Causas e Planos de Ação',
        generateAnalysis: 'Gerar Análise de Causa Raiz',
        actionList: 'Lista de Ações',
      },
    },
    equipmentManagement: {
      title: 'Gestão de Equipamento',
      submenu: {
        stoppingPoint: 'Apontamento de Paradas',
        stoppingChart: 'Gráfico de Paradas',
        approveStop: 'Aprovar Paradas',
      },
    },
    opsManagement: { title: 'Gestão de OPs' },
    manufacture: {
      title: 'Manufatura',
      submenu: {
        panel: 'Painel de Ordem de Produção',
        junkReport: 'Relatório de Refugo',
        approvals: 'Aprovação de Documentos',
      },
    },
    operationalSecurity: {
      title: 'Segurança Operacional',
      submenu: {
        impairments: 'Impairments',
        routineTests: 'Testes de Rotina',
        onlineMonitoring: 'Monitoramento Online',
      },
    },
    managementOfChanges: {
      title: 'Gestão de Mudanças',
      submenu: {},
    },
    dataEntry: {
      title: 'Entrada de Dados',
      submenu: {
        form: 'Formulário',
        manual: 'Manual',
        csv: 'CSV',
        logbook: 'Anotações',
      },
    },
    export: {
      title: 'Exportação',
      submenu: {
        data: 'Dados',
        event: 'Eventos',
      },
    },
    scale: { title: 'Balança' },
    operationalAlerts: { title: 'Alertas Operacionais' },
    alarmManagement: {
      title: 'Gestão de Alarmes',
      submenu: {
        report: 'Relatório',
      },
    },
    consistency: {
      title: 'Consistência',
      submenu: {
        productionAndProcess: 'Produção e Processo',
        equipmentStopping: 'Parada de Equipamento',
      },
    },
    log: { title: 'Log' },
    integratedMaintenance: {
      title: 'Manutenção Integrada',
      submenu: {
        serviceOrderPanel: 'Painel de Ordem de Serviço',
        serviceOrderProgramming: 'Programação de Ordem de Serviço',
        osGenerator: 'Geração de O.S. Preventiva',
        osOverview: 'Visão de O.S.',
        osHistory: 'Histórico de O.S.',
        plannedExecuted: 'Planejado x Executado',
        benchAppointment: 'Apontamento de Bancada',
        reports: 'Relatórios',
        telemetry: 'Telemetria',
      },
    },
    configuration: {
      operationalStructure: {
        title: 'Estrutura Operacional',
        submenu: {
          barcodeLabel: 'Código de Barras',
          parameters: 'Parâmetros',
          elementsTree: 'Árvore de Elementos',
          operator: 'Operador',
          shiftsAndTimes: 'Turnos e Horários',
          pdfTemplate: 'Templates PDF',
          token: 'Token',
          globalVariables: 'Variáveis Globais',
          unitMeasurement: 'Unidade de Medida',
          product: 'Produto',
        },
      },
      tags: {
        title: 'Tags',
        submenu: {
          tags: 'Tags',
          tagsGroup: 'Grupo de Tags',
          goals: 'Metas',
        },
      },
      alerts: {
        title: 'Alertas',
        submenu: {
          alertsGroup: 'Grupo de Alertas',
          alertsConfiguration: 'Configuração de Alertas',
        },
      },
      manufacture: {
        title: 'Manufatura',
        submenu: {
          defect: 'Defeitos',
          status: 'Status',
          variation: 'Variação',
          checklist: 'Lista de Controle',
          checklistItems: 'Itens da Lista de Controle',
          srcOrder: 'Roteiro de Ordem De Produção',
          checklistApprovalMatrix: 'Matriz de Aprovação',
          complementaryTable: 'Tabela Complementar',
          motiveGroup: 'Grupo de Motivos',
          inspectionLevel: 'Nível de Inspeção',
          acceptableQualityLevel: 'Nível de Qualidade Aceitável',
        },
      },
      operationalSecurity: {
        title: 'Segurança Operacional',
        submenu: {
          instrumentSeverity: 'Severidade',
          instrumentInterlock: 'Intertravamento',
          instrumentRisk: 'Risco',
          instrumentFrequency: 'Frequência',
          impairmentRule: 'Regras de Impairment',
          instrumentMitigationMeasures: 'Medidas de Mitigação',
          instrumentPrefix: 'Prefixo',
          instrument: 'Instrumento',
          userGroupTree: 'Árvore de Grupo de Usuários',
          approvalMatrix: 'Matriz de Aprovação',
        },
      },
      managementOfChanges: {
        title: 'Gestão de Mudanças',
        submenu: {
          areaCriticality: 'Criticidade de Área',
          risk: 'Riscos',
          responsibleArea: 'Área Responsável',
          approvalMatrix: 'Matriz de Aprovação',
        },
      },
      eventManager: {
        title: 'Gestão de Eventos',
        submenu: {
          frames: 'Eventos',
          motives: 'Motivos',
          complementaryTypes: 'Tipos Complementares',
        },
      },
      controlLoopManagement: { title: 'Malha de Controle' },
      oee: { title: 'OEE' },
      rootCause: {
        title: 'Análise Causa Raiz',
        submenu: {
          causes: 'Causas',
          rules: 'Regras',
        },
      },
      logbookGroup: { title: 'Grupo de Anotações' },
      integration: {
        title: 'Integração',
        submenu: {
          api: 'API',
          system: 'Sistema',
          laboratory: 'Laboratório',
          integrationParameters: 'Parâmetros de Integração',
        },
      },
      statisticalControl: {
        title: 'Controle Estatístico',
        submenu: {
          cards: 'Cartas',
          rules: 'Regras',
          help: 'Ajuda',
          diagnosticsList: 'Lista de Diagnósticos',
          correctiveActions: 'Ações Corretivas',
        },
      },
      manualCollector: {
        title: 'Coleta Manual',
        submenu: {
          collectGroup: 'Grupo de Coleta',
          collectTags: 'Tags de Coleta',
        },
      },
      integratedMaintenance: {
        title: 'Manutenção Integrada',
        submenu: {
          reportRegistration: 'Cadastro de Relatórios',
          inspectionRoutes: 'Rotas de Inspeção',
          materialsServices: 'Materiais e Serviços',
          modalities: 'Modalidades',
          frequency: 'Frequência',
          status: 'Status',
          maintenanceActivity: 'Atividade de Manutenção',
          priority: 'Prioridade',
          serviceOrderTypes: 'Tipos de Ordem de Serviço',
          laborRules: 'Regras Mão de Obra',
          services: 'Serviços',
        },
      },
      accessControl: {
        title: 'Controle de Acesso',
        submenu: {
          userRegistration: 'Usuários',
          userGroup: 'Grupo de Recursos',
          accessProfile: 'Perfil de Acesso',
        },
      },
    },
  },
  tinyMCE: {
    cellLocked: 'Célula bloqueada',
    cellUnlocked: 'Célula desbloqueada',
    columnLocked: 'Coluna bloqueada',
    columnUnlocked: 'Coluna desbloqueada',
    rowLocked: 'Linha bloqueada',
    rowUnlocked: 'Linha desbloqueada',
    cell: 'Célula',
    column: 'Coluna',
    row: 'Linha',
    blockEditing: 'Bloquear edição',
    enableEditing: 'Habilitar edição',
    lockCell: 'Bloquear edição da célula',
    unlockCell: 'Desbloquear edição da célula',
  },
  breadcrumbs: {
    home: 'Home',
    dashboard: {
      title: 'Dashboard',
    },
    analytics: {
      title: 'Análise',
      page: {
        analysisProcess: 'Análise de Processos',
        eventManager: 'Gestão de Eventos',
        correlation: 'Correlação',
        operator: 'Operador',
        comparativeBetweenPeriods: 'Comparativo Entre Períodos',
        reportPerPeriods: 'Reporte por Períodos',
      },
    },
    dataMining: {
      title: 'Mineração de Dados',
      page: {
        regression: 'Regressão',
        clustering: 'Clusterização',
      },
    },
    controlLoopManagement: {
      title: 'Malha de Controle',
      page: {
        performance: 'Desempenho',
        overview: 'Saúde de Malhas',
      },
    },
    statisticalControl: {
      title: 'Controle Estatístico',
      page: {
        exploratory: 'Exploratório',
        production: 'Produção',
        management: 'Gestão de Desvios',
      },
    },
    rootCause: {
      title: 'Análise de Causa Raiz',
      page: {
        actionsAndPlans: 'Causas e Planos de Ação',
        effects: 'Efeitos',
        charts: 'Gráficos',
        generateAnalysis: 'Gerar Análise de Causa Raiz',
        actionList: 'Lista de Ações',
      },
    },
    equipmentManagement: {
      title: 'Gestão de Equipamento',
      page: {
        stoppingPoint: 'Apontamento de Paradas',
        stoppingChart: 'Gráfico de Paradas',
        approveStop: 'Aprovar Paradas',
      },
    },
    opsManagement: { title: 'Gestão de OPs' },
    manufacture: {
      title: 'Manufatura',
      submenu: {
        panel: 'Painel de Ordem de Produção',
        junkReport: 'Relatório de Refugo',
      },
      page: {
        steps: 'Etapas',
      },
    },
    dataEntry: {
      title: 'Entrada de Dados',
      page: {
        form: 'Formulário',
        manual: 'Manual',
        csv: 'CSV',
        logbook: 'Anotações',
      },
    },
    export: {
      title: 'Exportação',
      page: {
        data: 'Dados',
        event: 'Eventos',
      },
    },
    scale: { title: 'Balança' },
    operationalAlerts: { title: 'Alertas Operacionais' },
    consistency: {
      title: 'Consistência',
      page: {
        productionAndProcess: 'Produção e Processo',
        equipmentStopping: 'Parada de Equipamento',
      },
    },
    log: { title: 'Log' },
    expired: { title: 'Contrato Expirado' },
    integratedMaintenance: {
      title: 'Manutenção Integrada',
      page: {
        serviceOrderPanel: 'Painel de Ordem de Serviço',
        serviceOrderProgramming: 'Programação de Ordem de Serviço',
        osGenerator: 'Geração de O.S. Preventiva',
        osOverview: 'Visão de O.S.',
        osHistory: 'Histórico de O.S.',
        plannedExecuted: 'Planejado x Executado',
        benchAppointment: 'Apontamento de Bancada',
        telemetry: 'Telemetria',
      },
    },
    configuration: {
      operationalStructure: {
        title: 'Estrutura Operacional',
        page: {
          barcodeLabel: 'Código de Barras',
          parameters: 'Parâmetros',
          elementsTree: 'Árvore de Elementos',
          shiftsAndTimes: 'Turnos e Horários',
          pdfTemplate: 'Templates PDF',
          operator: 'Operador',
          token: 'Token',
          globalVariables: 'Variáveis Globais',
          unitMeasurement: 'Unidade de Medida',
          product: 'Produto',
        },
      },
      tags: {
        title: 'Tags',
        page: {
          tags: 'Tags',
          tagsGroup: 'Grupo de Tags',
          goals: 'Metas',
        },
      },
      operationalSecurity: {
        title: 'Segurança Operacional',
        page: {
          instrumentSeverity: 'Severidade',
          instrumentRisk: 'Risco',
          instrumentInterlock: 'Intertravamento',
          instrumentFrequency: 'Frequência',
          impairmentRule: 'Regras de impairment',
          instrumentMitigationMeasures: 'Medidas de Mitigação',
          instrumentPrefix: 'Prefixo',
          instrument: 'Instrumentos',
          userGroupTree: 'Árvore de grupo de usuários',
          approvalMatrix: 'Matriz de aprovação',
        },
      },
      managementOfChanges: {
        title: 'Gestão de Mudanças',
        page: {
          areaCriticality: 'Criticidade de Área',
          risk: 'Riscos',
          responsibleArea: 'Área Responsável',
          approvalMatrix: 'Matriz de Aprovação',
        },
      },
      alerts: {
        title: 'Alertas',
        page: {
          alertsGroup: 'Grupo de Alertas',
          alertsConfiguration: 'Configuração de Alertas',
        },
      },
      eventManager: {
        title: 'Gestão de Eventos',
        page: {
          frames: 'Eventos',
          motives: 'Motivos',
          complementaryTypes: 'Tipos Complementares',
        },
      },
      controlLoopManagement: { title: 'Malha de Controle' },
      oee: { title: 'OEE' },
      rootCause: {
        title: 'Análise Causa Raiz',
        page: {
          causes: 'Causas',
          rules: 'Regras',
        },
      },
      logbookGroup: { title: 'Grupo de Anotações' },
      integration: {
        title: 'Integração',
        page: {
          api: 'API',
          system: 'Sistema',
          laboratory: 'Laboratório',
          integrationParameters: 'Parâmetros de Integração',
          details: 'Detalhes',
        },
      },
      statisticalControl: {
        title: 'Controle Estatístico',
        page: {
          cards: 'Cartas',
          rules: 'Regras',
          help: 'Ajuda',
          diagnosticsList: 'Lista de Diagnósticos',
          correctiveActions: 'Ações Corretivas',
        },
      },
      manualCollector: {
        title: 'Coleta Manual',
        page: {
          collectGroup: 'Grupo de Coleta',
          collectTags: 'Tags de Coleta',
        },
      },
      integratedMaintenance: {
        title: 'Manutenção Integrada',
        page: {
          reportRegistration: 'Cadastro de Relatórios',
          inspectionRoutes: 'Rotas de Inspeção',
          materialsServices: 'Materiais e Serviços',
          modalities: 'Modalidades',
          frequency: 'Frequência',
          status: 'Status',
          maintenanceActivity: 'Atividade de Manutenção',
          priority: 'Prioridade',
          serviceOrderTypes: 'Tipos de Ordem de Serviço',
          laborRules: 'Regras Mão de Obra',
          services: 'Serviços',
        },
      },
      manufacture: {
        title: 'Manufatura',
        page: {
          defect: 'Defeito',
          status: 'Status',
          variation: 'Variação',
          checklist: 'Lista de Controle',
          checklistItems: 'Itens da Lista de Controle',
          srcOrder: 'Roteiro de Ordem de Produção',
          checklistApprovalMatrix: 'Matriz de Aprovação',
          complementaryTable: 'Tabela Complementar',
          motiveGroup: 'Grupo de Motivos',
          inspectionLevel: 'Nível de Inspeção',
          acceptableQualityLevel: 'Nível de Qualidade Aceitável',
        },
      },
      accessControl: {
        title: 'Controle de Acesso',
        page: {
          userRegistration: 'Usuários',
          userGroup: 'Grupo de Recursos',
          accessProfile: 'Perfil de Acesso',
        },
      },
      unitParameters: { title: 'Parâmetros da Unidade' },
      reports: { title: 'Relatórios' },
    },
  },
  components: {
    shortcuts: {
      title: 'Atalhos',
      shortcut: 'Atalho',
      new: 'Novo Atalho',
      creator: 'Criador',
      moreThanOnePeriod: 'Mais de um período selecionado',
      seeAll: 'Ver todos',
      saved: 'Salvos',
    },
    shareButton: {
      copyLink: 'Copiar link',
      print: 'Imprimir',
      exportPdf: 'Exportar para PDF',
      exportCsv: 'Exportar para CSV',
      exportHorizontalCsv: 'Exportar para CSV horizontal',
      share: 'Compartilhar',
      copied: 'Link copiado!',
      loadingFile: 'Gerando arquivo...',
    },
    dateSelector: {
      today: 'Hoje',
      yesterday: 'Ontem',
      lastWeek: 'Última semana',
      lastMonth: 'Último mês',
      harvest: 'Safra',
      weekSelect: 'Selecione a semana',
    },
    cacheBuster: {
      newUpdate: 'Nova atualização!',
      updateDescription:
        'Há uma nova atualização disponível, clique em "Atualizar" para o sistema ser atualizado',
    },
    tagSelect: {
      recents: 'Recentes',
    },
    frameSelect: {
      recents: 'Recentes',
    },
    layout: {
      menu: {
        dashboard: {
          addingDashboard: 'Adicionando novo dashboard...',
          noTitle: 'Sem título',
          dashboardSuccess: 'Dashboard criado com sucesso',
          dashboardError:
            'Houve um problema ao tentar criar o dashboard, por favor, tente novamente...',
          newDashboard: 'Novo dashboard',
        },
        buttonConfig: {
          exitConfiguration: 'Sair da configuração',
          configuration: 'Configuração',
        },
      },
      header: {
        alert: {
          notification: 'Notificações',
        },
        dropdown: {
          refeshApply: 'Atualize a página para aplicá-la',
          refreshNow: 'Atualizar agora',
          unitSuccess: 'Dados unidade carregados com sucesso!',
          translationSuccess: 'Tradução carregada com sucesso!',
          industrialUnits: 'Unidades industriais',
          languages: 'Idiomas',
          language: 'Idioma',
        },
      },
    },
    richTextInput: {
      small: 'Pequeno',
      normal: 'Normal',
      large: 'Grande',
      huge: 'Enorme',
    },
  },
  expired: {
    title: 'Expiração',
    paragragh:
      'O tempo do seu contrato expirou, por favor entre em contato com o administrador do sistema',
    key: 'Coloque aqui a chave para a renovação do contrato',
    timeToExpire:
      'O contrato expirará em {count, plural, one {# dia} other {# dias}}',
    renewTextButton: 'clique aqui para renovar',
  },
  statisticalControl: {
    title: 'Controle estatístico',
    noTagCep: 'Tag não se aplica ao CEP',
    byPeriod: 'Por período',
    card: 'Carta',
    cards: 'Cartas',
    zones: 'Zonas',
    limits: 'Limites',
    diagnostic: 'Diagnóstico',
    diagnosticConsolidated: 'Diagnóstico consolidado no período',
    diagnosticList: 'Lista de diagnóstico',
    action: 'Ação',
    actionConsolidated: 'Ação consolidada no período',
    actionList: 'Lista de ação',
    yScales: 'Escalas Y',
    pleaseInput: 'Por favor, insira',
    exploratory: {
      imr: 'I-MR',
      ewma: 'EWMA',
      xbarR: 'XBarra - R',
      xbarS: 'XBarra - S',
      lambda: 'Constante de amortecimento',
      k: 'Coeficiente de abertura dos limites de controle',
      mean: 'Média alvo',
      standardDeviation: 'Desvio padrão alvo',
      standardDeviationWithin: 'Desvio padrão (within)',
      standardDeviationOverall: 'Desvio padrão (overall)',
      analysis: 'Análise estatística do processo',
      graphType: 'Tipo do gráfico',
      capacityAnalysis: 'Análise de capacidade de processo',
      cp: 'CP',
      cpi: 'CPI',
      cps: 'CPS',
      cpk: 'CPK',
      pp: 'PP',
      ppi: 'PPI',
      pps: 'PPS',
      ppk: 'PPK',
      input: 'Entrada',
      outSector: 'Fora do setor',
      expected: 'Esperado',
      real: 'Real',
      li: 'LI',
      ls: 'LS',
      lic: 'LIC',
      lc: 'LC',
      lsc: 'LSC',
      histogram: 'Histograma',
      normalDistribution: 'Distribuição normal',
      quantity: 'Quantidade de amostra',
      especificationLimits: 'Limites de especificação',
      simulateControlLimits: 'Simular limites de controle',
      hidePoints: 'Esconder pontos',
      pointsAverage: 'Média dos pontos',
      startTime: 'Início do período analisado',
      endTime: 'Fim do período analisado',
      colectedValue: 'Valor coletado',
    },
    production: {
      diagnosticItems: 'Itens de diagnóstico',
      actionItems: 'Itens de ações corretivas',
      inserted: 'Inseriu',
      edited: 'Editou',
      newPoint: 'Novo ponto',
      outLimit: 'Fora do limite',
      autoUpdate: 'Reprodução automática',
      help: 'Ajuda',
      lastUser: 'Último usuário',
      point: 'Ponto',
      finishNote: 'Finalizar apontamento',
    },
    management: {
      noInformation: 'Sem informação para o dia',
      noIndication: 'Sem indicação',
      showAll: 'Exibir tudo',
      showPointed: 'Exibir apontados',
      pareto: 'Pareto',
      percent: 'Porcentagem',
      pie: 'Pizza',
      spc: 'CEP',
      finished: 'Finalizado',
      notFinished: 'Não Finalizado',
    },
    rules: {
      title: 'Regras',
      of: 'de',
      outEspecificationLimit: 'ponto fora dos limites de especificação',
      outControlLimit: 'ponto fora dos limites de controle',
      sameSide: 'pontos consecutivos do mesmo lado da linha central',
      increasingOrDescreasing: 'pontos consecutivos aumentando ou diminuindo',
      aboveOrBelow: 'pontos consecutivos, alternando acima e abaixo',
      twoStandardDeviation:
        'pontos consecutivos maiores que 2 desvios padrões a partir da linha central (mesmo lado)',
      oneStandardDeviation:
        'pontos consecutivos maiores que 1 desvio padrão a partir da linha central (mesmo lado)',
      insideOneStandardDeviation:
        'pontos consecutivos dentro de 1 desvio padrão da linha central (qualquer lado)',
      anySide:
        'pontos consecutivos maiores que um desvio padrão a partir da linha central (qualquer lado)',
    },
    configuration: {
      card: {
        newRevision: 'Nova revisão',
        deleteCard:
          'Tem certeza que deseja deletar a carta? (Todas suas revisões também serão deletadas)',
        createCardRevision: 'Tem certeza que deseja criar uma nova revisão?',
        rulingToShowTags:
          'Exibindo apenas tags com regras cadastradas e que não possuem outra carta',
        cardProject: 'Projeto de carta',
        revisionMotive: 'Motivo da revisão',
        scale: 'Escala',
        yAxis: 'Escala y',
        yAxisUnit: 'Unidade da escala y',
        lower: 'Inferior',
        cannotBeGreaterMax: 'Não pode ser maior que o máximo',
        center: 'Central',
        upper: 'Superior',
        cannotBeLowerMin: 'Não pode ser menor que o mínimo',
        lowerEspecification: 'Limite inferior de especificação',
        upperEspecification: 'Limite superior de especificação',
        helpVariable: 'Ajuda de padrão da variável',
        revisionDate: 'Data de revisão da carta',
        revisionPeriod: 'Período de vigência desta revisão',
        revisionInserted: 'Revisão inserida com sucesso',
        existingCard:
          'Revisão não inserida, há outras revisões com a mesma data. Revisões:',
        controlLimitsMessage:
          'Se os limites de controle não forem inseridos, o sistema os calculará automaticamente',
      },
      rules: {
        title: 'Regra',
        ruleZero: "'K' ponto(s) fora dos limites de especificação",
        ruleOne: "'K' ponto(s) fora dos limites de controle",
        ruleTwo: "'K' ponto(s) consecutivos no mesmo lado da linha central",
        ruleThree: "'K' ponto(s) consecutivos, todos aumentando ou diminuindo",
        ruleFour: "'K' ponto(s) consecutivos, alternando acima e abaixo",
        ruleFive:
          "'K-1' de 'K' ponto(s) consecutivos maiores que 2 desvios padrão da linha central (mesmo lado)",
        ruleSix:
          "'K-1' de 'K' ponto(s) consecutivos maiores que 1 desvio padrão da linha central (mesmo lado)",
        ruleSeven:
          "'K' ponto(s) consecutivos dentro de um desvio padrão da linha central (em ambos os lados)",
        ruleEight:
          "'K' ponto(s) consecutivos maiores que 1 desvio padrão da linha central (ambos os lados)",
        kFactor: 'Fator-k',
        tagQuantity: 'Quantidade de tags',
        rulesQuantity: 'Quantidade de regras',
        cantDeleteRule:
          'Não é possível excluir essa regra pois há cartas criadas com uma ou mais tags relacionadas',
        cantDeselectTags:
          'Não é possível desassociar tags já relacionadas com uma carta',
      },
    },
  },
  alert: {
    title: 'Alerta',
    notSeen: 'Não visualizados',
    noCause: 'Sem causa',
    withStaggering: 'Com escalonamento',
    notificationsSent: 'Notificações enviadas',
    seen: 'Visualizado',
    cause: 'Causa',
    chat: 'Chat',
    notificate: 'Notificar',
    sendNotification: 'Enviar notificação',
    configuration: {
      defaultMessage: 'Mensagem padrão',
      helpChain: 'Cadeia de ajuda',
      initialGroups: 'Grupos iniciais',
      lastGroup: 'Último grupo',
      groupNotFound: 'Grupo não encontrado',
      permissions: 'Permissões',
      maxTimeView: 'Tempo máximo de visualização (minutos)',
      userPermission: 'Permissão para usuários',
      showPopup: 'Exibir alerta através de um pop-up',
      redirect:
        'Redirecionar para o apontamento de paradas ao visualizar o pop-up',
      redirectExtra:
        'Está opção somente funcionará se a opção de exibir pop-up estiver selecionada',
    },
  },
  auth: {
    signin: {
      typeUser: 'Digite seu usuário',
      typePassword: 'Digite sua senha',
      failure: 'O usuário ou senha está incorreto, verifique seus dados',
      unauthorized: 'O usuário não tem permissão de acesso a nenhuma unidade',
      inactiveUser: 'Usuário inativo, entre em contato com o administrador',
      withoutContract: 'O usuário não possui unidades contratadas',
      apiUrl: 'URL da API ausente, verifique sua configuração',
      failurePassword:
        'tentativas restantes, verifique seus dados ou contate o administrador',
      failurePasswordLimit:
        'Tentativas de login excedida, solicite uma nova senha ao administrador',
      welcomeToIntelup: 'Bem-vindo à plataforma Intelup',
      permissionNotification:
        'Para prosseguir com a utilização da plataforma será necessário permitir o armazenamento de dados como nome, e-mail e foto. Caso não permita não será possível utilizar a plataforma.',
      permissionNotificationInformations:
        'O sistema utiliza dados pessoais como nome, e-mail e foto. Esses dados NÃO serão utilizados para meios de publicidade e NÃO serão vendidos a terceiros. O uso é único e exclusivo para o funcionamento adequado da plataforma.',
      permissionNotificationQuestion:
        'Permitir que a plataforma armazene estes dados?',
    },
    changePassword: {
      title: 'Alteração de senha',
      comparePassword: 'As senhas digitadas são inconsistentes',
      newPasswordNecessity: 'Alteração de senha necessária:',
      newPassword: 'Nova senha',
      typeNewPassword: 'Digite a nova senha!',
      confirmPassword: 'Confirme a nova senha',
      typeConfirmPassword: 'Digite a confirmação da nova senha!',
    },
    strength: {
      eightCaracters: '8 caracteres',
      oneUppercase: '1 maiúscula',
      oneLowercase: '1 minúscula',
      oneNumber: '1 número',
      oneSpecial: '1 caractere especial',
    },
  },
  controlLoopManagement: {
    title: 'Malha de controle',
    manipulatedVariable: 'Variável manipulada',
    processVariable: 'Variável de processo',
    setPoint: 'Set point',
    performance: {
      title: 'Desempenho',
      mainChart: {
        operationMode: 'Modo de operação',
      },
    },
    tune: {
      title: 'Sintonia',
      target: 'Alvo',
      kp: 'Kp',
      ki: 'Ki',
      kd: 'Kd',
      standardDeviationTarget: 'Alvo do desvio padrão',
    },
    reliability: {
      title: 'Confiabilidade',
    },
    stats: {
      processAnalysis: 'Análise estatística do processo',
      permanenceTime: 'Tempo de permanência',
      absoluteError: 'Erro absoluto médio',
      setPointNumber: 'Número de travessias do set point',
    },
    operationMode: {
      title: 'Modo de operação',
    },
    overview: {
      loopGroup: 'Grupo de malha',
      autoTime: 'Tempo em automático',
    },
    configuration: {
      mnemony: 'Mnemonia',
      newGroup: 'Novo grupo',
      mnemonyExist: 'Mnemonia já existe',
      autoCreate: 'Criar automaticamente',
      percentage: 'Porcentagem',
      percentuals: {
        automaticManual: 'Percentuais - AM',
        automaticManualDescription:
          'Faixas dos gauges exibidos na Saúde das Malhas de Controle - AM (maior - melhor)',
        standardDeviation: 'Percentuais - Desvio Padrão',
        standardDeviationDescription:
          'Faixas dos gauges exibidos na Saúde das Malhas de Controle - Desvio Padrão (menor - melhor)',
      },
      percentageInfo:
        'Informe o limite inferior que representará o começo da faixa do gauge sendo definido',
      color: 'Cor',
      afterCreation: 'Disponível após criação da malha',
    },
  },
  dataMining: {
    regression: {
      relatedTags: 'Tags relacionadas',
      r2: 'r²',
      r2Adjusted: 'r² ajustado',
      significanceF: 'F de significação',
      coefficients: 'Coeficientes',
      standardError: 'Erro padrão',
      matrix: 'Matriz',
      selectTagsMatrix: 'Selecione tags para gerar a matriz',
    },
    clustering: {
      cluster: 'Cluster',
      clusters: 'Clusters',
      yAxis: 'Escala y',
      xAxis: 'Escala x',
    },
  },
  equipmentManagement: {
    stoppingChart: {
      sector: 'Setor',
      stopTime: 'Tempo de parada',
      percentage: 'Percentual',
      cumulativePercentage: 'Percentual acumulado',
      notPointed: 'Não apontados',
      showNotPointed: 'Mostrar não apontados',
      notPlanned: 'Não planejado',
      planned: 'Planejado',
      quantity: 'Quantidade',
      hours: 'Horas',
      dataToShow: 'Dados a serem exibidos',
      inOperation: 'Em operação ',
      paretoByMotive: 'Diagrama de pareto por motivo',
      paretoByGroup: 'Diagrama de pareto por grupo de motivos',
      paretoByFrame: 'Diagrama de pareto por evento',
      pizzaByMotive: 'Porcentagem por motivo',
      pizzaByGroup: 'Porcentagem por grupo de motivos',
      pizzaByFrame: 'Porcentagem por evento',
      ganttByMotive: 'Linha do tempo por motivo',
      ganttByGroup: 'Linha do tempo por grupo de motivos',
      ganttByFrame: 'Linha do tempo por evento',
    },
    stoppingPoint: {
      manualPoint: 'Apontamento manual',
      totalTime: 'Tempo total',
      finished: 'Finalizada',
      open: 'Aberta',
      annul: 'Anulada',
      stillOpen: 'A parada ainda está ocorrendo',
      splitStop: 'Dividir parada',
      deleteStop: 'Excluir parada',
      split: 'Dividir',
      serviceOrder: 'Ordem de Serviço',
      separatedByCalendar:
        'Este evento pode estar sendo separado por um calendário, caso seja necessário apontar diferentes motivos, complementos ou observações, por favor divida a parada',
      historic: 'Histórico',
      deletePoint: 'Deletar ponto',
      updates: 'Atualizações',
      notPointed: 'Não apontados',
      pointed: 'Apontados',
      showMicroStop: 'Exibir micro-parada',
      motivesGroup: 'Grupo de motivos',
      annulStoppingConfirmTitle: 'Você deseja anular este item?',
      annulStoppingConfirmContent:
        'Ao anular esta parada, ela não será deletada, porém não será mais contabilizada.',
      enableStoppingConfirmTitle: 'Você deseja habilitar este item?',
      enableStoppingConfirmContent:
        'Ao habilitar esta parada, ela voltará a ser contabilizada.',
    },
    approveStop: {
      show: 'Exibir',
      approve: 'Aprovar',
      reprove: 'Reprovar',
      approved: 'Aprovado',
      reproved: 'Reprovado',
      historic: 'Histórico',
    },
    configuration: {
      importItems: 'Importar itens',
      sussesImportingEvent: 'Evento importado com sucesso!',
      relationImportWarning:
        'Algumas configurações não foram encontradas na planta atual, portanto não foram importadas!',
    },
  },
  consistency: {
    equipmentStop: {
      integratedStatus: 'Status integração',
      notApproved: 'Não aprovado',
      approved: 'Aprovado',
      integrating: 'Em integração',
      integrated: 'Integrado',
      integratedError: 'Erro na integração',
      homologation: 'Homologação',
      frameDescription: 'Descrição do evento',
      daysReleased: 'Dias liberados',
      currentDay: 'Dia atual',
      stopsList: 'Lista de paradas',
      loadingReconciliation:
        'Reconciliando paradas de equipamento, isso pode levar alguns minutos!',
    },
    productionProcess: {
      releasedBulletin: 'Boletim liberado',
      daysBlocked: 'Dias bloqueados',
      daysReleased: 'Dias liberados',
      currentDay: 'Dia atual',
      wantReconciliation: 'Deseja reconciliar?',
      tagExternal: 'Tag externo',
      obtainedValues: 'Valores obtidos',
      limits: 'Limites de Especificação',
      substituteValue: 'Valor substituto',
      justification: 'Justificativa',
      changes: 'Alterações',
      textValue: 'Valor texto',
      numberValue: 'Valor numérico',
      substituteVariableType: 'Tipo de variável substituta',
      numeric: 'Numérico',
      alphanumericValue: 'Valor alphanumérico',
      substituteValueDecimalsInfo:
        'Valores numéricos serão formatados de acordo com as casas decimais da tag',
      approved: 'Aprovado',
      available: 'Disponibilizar',
      formEditAlertMessage:
        'O status da integração será resetado, deseja continuar?',
      clickToShowData:
        "Selecione o período e clique em 'Visualizar' para exibir os dados",
    },
  },
  log: {
    title: 'Log',
    login: 'Login',
    navigation: 'Navegação',
    integration: 'Integração',
    productionProcess: 'Produção e processo',
    equipmentStop: 'Parada de Equipamento',
    dataReconciliation: 'Reconciliação de dados',
    dataEntry: 'Entrada de dados',
    colect: 'Coleta manual',
    manual: 'Manual',
    dateSchedule: 'Data/Cronograma',
    consolidation: 'Consolidação',
    consolidatedLog: 'Log consolidado',
    appointmentStop: 'Apontamento de paradas',
    rootCause: 'Análise de causa raiz',
    equipmentManager: 'Gestão de equipamento',
    system: 'Sistema',
    action: 'Ação',
    logged: 'Realizou login/logoff no sistema',
    approveStop: 'Aprovar parada',
    errors: {
      conversion: 'Erro na conversão',
      eventManager: 'Erro na gestão de eventos',
      consolidation: 'Erro na consolidação',
    },
    actions: {
      execution: {
        conversion: 'Conversão foi executada',
        eventManager: 'Gestão de eventos foi executado',
        integrationAPI: 'Integração de API executado',
        productionOrder: 'Ordem de Produção calculado',
        productionOrderAutomaticAppointment:
          'Apontamento automático da ordem de produção foi executado',
        route: 'Rota de inspeção foi executada',
      },
      access: 'Acessou',
      insert: 'Inseriu',
      delete: 'Deletou',
      update: 'Atualizou',
      reversed: 'Estornou',
      login: 'Login',
      logoff: 'Logoff',
      loginApp: 'Login - APP',
      logoffApp: 'Logoff - APP',
      integrate: 'Integrar',
      reconciliate: 'Reconciliar',
      insertPoint: 'Inserir apontamento',
      approve: 'Aprovar',
      approved: 'Aprovado',
      reproved: 'Reprovado',
      insertTag: 'Tag inserida',
      editTag: 'Tag editada',
      deletedTag: 'Tag deletada',
      tagsDeletedInfo:
        'Data do primeiro ponto: {firstDate}, data do último ponto: {lastDate}, quantidade de pontos: {count}',
      tagsInsertedInfo: 'Valor: {currentValue}, data do ponto: {pointDate}',
      tagsEditedInfo:
        'Valor anterior: {prevValue}, Valor atual: {currentValue}, Data do ponto: {pointDate}',
      insertStepItemNote: 'Inserir apontamento (ordem de produção)',
      updateStepItemNote: 'Editar apontamento (ordem de produção)',
      deleteStepItemNote: 'Deletar apontamento (ordem de produção)',
      reverseStepItemNote: 'Estornar apontamento (ordem de produção)',
      insertManufactureMotive: 'Inserir motivo (manufatura)',
      updateManufactureMotive: 'Editar motivo (manufatura)',
      insertManufactureMotiveGroup: 'Inserir grupo de motivo (manufatura)',
      updateManufactureMotiveGroup: 'Editar grupo de motivo (manufatura)',
      updateShortcut:
        'Nova Descrição: {description} | Nova Data de início: {date_init} | Nova Data de fim: {date_end} | Novos IDs de Tags: {tags_id} | Antiga Descrição: {old_description} | Antiga Data de início: {old_date_init} | Antiga Data de fim: {old_date_end} | Antigos IDs de Tags: {old_tags_id}',
      insertShortcut:
        'Descrição: {description} | Data de início: {date_init} | Data de fim: {date_end} | IDs de Tags: {tags_id}',
      deleteShortcut:
        'Descrição: {description} | Data de início: {date_init} | Data de fim: {date_end} | IDs de Tags: {tags_id}',
      updateShortcutFrame:
        'Nova Descrição: {description} | Nova Data de início: {date_init} | Nova Data de fim: {date_end} | Novos IDs de Quadros: {frames_id} | Antiga Descrição: {old_description} | Antiga Data de início: {old_date_init} | Antiga Data de fim: {old_date_end} | Antigos IDs de Quadros: {old_frames_id}',
      insertShortcutFrame:
        'Descrição: {description} | Data de início: {date_init} | Data de fim: {date_end} | IDs de Quadros: {frames_id}',
      deleteShortcutFrame:
        'Descrição: {description} | Data de início: {date_init} | Data de fim: {date_end} | IDs de Quadros: {frames_id}',
    },
  },
  dataEntry: {
    messages: {
      lessEqualThan: 'Digite um valor menor ou igual a',
      greaterEqualThan: 'Digite um valor maior ou igual a',
      outLowerEspecificationLimit:
        'Valor fora do limite inferior de especificação',
      outUpperEspecificationLimit:
        'Valor fora do limite superior de especificação',
      outEspecificationLimit: 'Fora do limite de especificação',
      outValidLimit: 'Fora do limite válido',
      logbookSuccess: 'Anotação realizada com sucesso',
      logbookError: 'Erro ao tentar realizar a anotação',
      logbookEdit: 'Anotação alterada com sucesso',
      logbookDelete: 'Anotação deletada com sucesso',
    },
    csv: {
      dataError: 'O formato da data está incorreto',
      downloadExample: 'Baixar exemplo',
      selectCsv: 'Selecionar CSV',
    },
    manual: {
      dateAndTime: 'Data e horário',
    },
    colect: {
      colectGroupAndTags: 'Grupo de coleta / Coleta com tags',
      selectGroup: 'Selecione o grupo',
      inputManualTags: 'Tags do tipo entrada manual',
      noneInputManualTagSelected:
        'Nenhuma tag do tipo entrada manual selecionada',
      reloadData: 'Recarregar os dados da tabela',
    },
    notes: {
      title: 'Anotações',
      notesField: 'Campo de anotações',
    },
  },
  opsManagement: {
    redo: 'Recalcular produção',
    externalCode: 'Código externo',
    description: 'Descrição',
    codeDescription: 'Código / Descrição',
    plannedExecuted: 'Produzidas / Planejadas',
    order: 'Ordem',
    inProduction: 'Em produção...',
    productionStartIn: 'Produção iniciará em',
    lastProcessing: 'Último processamento',
    validatingOrder: 'Validando ordem de produção',
    reviewOrder: 'Aguardando revisão',
    concludedIn: 'Concluído em',
    addProductionOrder: 'Adicionar ordem de produção',
    filterByDate: 'Filtro por data',
    filterByCode: 'Filtro por código',
    productionOrderForm: 'Formulário de ordem de produção',
    typeCode: 'Digite o código do item',
    typeExternalCode: 'Digite o código externo do item',
    typeDescription: 'Digite a descrição',
    planned: 'Quantidade planejada',
    typeMin: 'Digite a quantidade mínima de produção',
    typeUniqueCode: 'Digite um código único',
    dateToStart: 'Data para iniciar',
    dateToEnd: 'Data para finalizar',
    productionList: 'Lista de produção',
    percentageTolerance: 'Tolerância',
    produced: 'Quantidade Produzida',
    reviewForm: 'Revisar Item da Ordem de Produção',
    review: 'Revisar',
    viewReview: 'Visualizar Revisão',
    calculatedQuantity: 'Quantidade Apurada',
    revisedQuantity: 'Quantidade Revisada',
  },
  manufacture: {
    appointmentNotAllowedByStatus:
      'Este apontamento não pode ser realizado pois a ordem encontra-se no status "{status}"',
    actionNotAllowedByStatus: 'A ordem encontra-se no status "{status}"',
    archivedList:
      'Este apontamento não pode ser realizado pois a lista de controle arquivada.',
    noPermissionForAction: 'Você não possui permissão para essa ação.',
    reopenConfirmation: 'Tem certeza que deseja reabrir a ordem?',
    itemVinculatedToChecklist: 'O item está sendo utilizado em uma lista.',
    productionOrderHistoric: 'Histórico de ordem de produção',
    conflictNoteInfo: 'Este período conflita com um apontamento existente',
    duplicatedCode:
      'O código da ordem já existe para esse produto, escolha outro',
    duplicatedSrcOrderCode:
      'Já existe um roteiro de ordem de produção com esse código ({description}), por favor escolha outro código.',
    showDefectLocation: 'Exibir local do defeito',
    exportHorizontalList: 'Lista Horizontal',
    exportExpandedList: 'Lista Expandida',
    serialCode: 'Código serial',
    searchSerialByDate: 'Pesquisa de serial por período',
    searchPoBySerialCode: 'Pesquisa por O.P.',
    searchSerialCode: 'Pesquisa por código serial',
    searchLot: 'Pesquisa por lote',
    extraFields: 'Campos extras',
    defects: 'Defeitos',
    status: 'Status',
    line: 'Linha',
    product: 'Produto',
    noOpsToShow: 'Sem ordens de produção para exibir',
    orderCode: 'Código da ordem',
    itemCode: 'Código do item',
    quantityPlanned: 'Quantidade planejada',
    quantityFulfilled: 'Quantidade total apontada',
    quantityGood: 'Quantidade boa',
    quantityJunk: 'Quantidade refugo',
    quantityProduced: 'Quantidade produzida',
    quantityAvailable: 'Quantidade disponível',
    sourceLot: 'Lote origem',
    destinationLot: 'Lote destino',
    junkMotiveAppointments: 'Apontamento de motivo de refugo',
    defect: 'Defeito',
    itemUnit: 'Unidade',
    planner: 'Planejador',
    operator: 'Operador',
    itemDescription: 'Descrição do item',
    generated: 'Gerada',
    planned: 'Planejada',
    scheduled: 'Programada',
    running: 'Executando',
    inCompletion: 'Em finalização',
    finished: 'Finalizada',
    canceled: 'Cancelada',
    stageList: 'Etapas',
    step: 'Etapa',
    steps: 'Etapas',
    components: 'Componentes',
    pieceHour: 'Peças por hora',
    location: 'Local',
    generalPoData: 'Dados gerais da ordem de produção',
    srcOrderCode: 'Código do roteiro',
    umInventory: 'U.M. Estoque',
    umProduction: 'U.M. Produção',
    totalTime: 'Tempo execução realizado',
    startTime: 'Data da geração',
    generationUser: 'Usuário da geração',
    changeTime: 'Data da alteração',
    changeUser: 'Usuário da alteração',
    startHour: 'Hora início',
    endHour: 'Hora término',
    referenceDate: 'Data de referência',
    externalCode: 'Código externo',
    production: 'Produção',
    junk: 'Refugo',
    good: 'Boa',
    subProduct: 'Subproduto',
    consumption: 'Consumo',
    consumptionSummary: 'Resumo dos componentes de consumo',
    stopsList: 'Lista de paradas',
    pO: 'O.P.',
    searchProductionOrder: 'Pesquisar O.P.',
    boolean: 'Status de conformidade',
    optionList: 'Lista de opções',
    validate: 'Validar',
    validateAppointment: 'Validar apontamento',
    notPointed: 'Não apontados',
    pointed: 'Apontados',
    itemType: 'Tipo do item',
    selectedOptionText: 'Texto da opção selecionada',
    notSelectedOptionText: 'Texto da opção não selecionada',
    ok: 'Conforme',
    notOk: 'Não conforme',
    element: 'Elemento',
    defaultValue: 'Valor base',
    checklist: 'Lista de controle',
    importJustification: 'Justificativa de importação',
    frequency: 'Frequência',
    productCode: 'Código do produto',
    scriptCode: 'Código do roteiro',
    productDescription: 'Descrição do produto',
    scriptDescription: 'Descrição do roteiro',
    addChecklist: 'Adicionar lista de controle',
    hour: 'A cada hora',
    shift: 'A cada turno',
    start: 'Ao iniciar a ordem de produção',
    end: 'Ao finalizar a ordem de produção',
    volume: 'Por volume produzido',
    every: 'A cada',
    addProduct: 'Adicionar produto',
    addStep: 'Adicionar etapa',
    addStepItem: 'Adicionar item da etapa',
    addComponent: 'Adicionar componente',
    addSubstituteComponent: 'Adicionar componente substituto',
    calculationStep: 'Finalização da etapa',
    appointmentType: 'Tipo do apontamento',
    productionOrder: 'Ordem de produção',
    unitMeasurement: 'Unidade de medida',
    newProductionOrder: 'Nova ordem de produção',
    checkListItem: 'Item da lista de controle',
    checkListItemAlreadyAssociated:
      'Este item já está associado a uma lista de controle, portanto não é possível realizar alterações.',
    itemAlreadyAssociated:
      'Este item já está associado a um roteiro, portanto não é possível realizar alterações.',
    itemAlreadyAssociatedPO:
      'Este item já está associado a uma ordem de produção, portanto não é possível realizar alterações.',
    appointment: 'Apontamento',
    seeCompletePo: 'Ver O.P. completa',
    cancelPo: 'Cancelar O.P.',
    poCanceled: 'O.P. Cancelada',
    workInstruction: 'Instrução de trabalho',
    variation: 'Variação',
    derivation: 'Derivação',
    newDerivation: 'Nova derivação',
    createDerivation: 'Tem certeza que deseja criar uma nova derivação?',
    derivationInserted: 'Derivação inserida com sucesso',
    substituteProduct: 'Produtos substitutos',
    automatic: 'Automático',
    manual: 'Manual',
    startType: 'Tipo de início',
    endType: 'Tipo de fim',
    expectedStartDate: 'Data de iníco prevista',
    expectedEndDate: 'Data de fim prevista',
    startConfirmation: 'Tem certeza que deseja iniciar a O.P.?',
    endConfirmation: 'Tem certeza que deseja finalizar a O.P.?',
    startPo: 'Iniciar O.P.',
    endPo: 'Enviar para finalização',
    allowInstruments: 'Permitir vincular instrumentos',
    groupSelectionView: 'Exibir apenas para os grupos selecionados',
    instruments: 'Instrumentos',
    elements: 'Elementos',
    instrumentsIsVinculated:
      'Os instrumentos que estão vinculados ao elemento serão utilizados.',
    blockedForInformativeType:
      'Este cadasdro não está liberado para itens do tipo "informativo"',
    ignoreChecklist: 'Ignorar lista de controle?',
    alreadyIgnoredChecklist:
      'Esta ordem já está configurada para ignorar a lista de controle.',
    confirmIgnoreChecklist: 'Tem certeza que quer ignorar a lista de controle?',
    finalizationForm: 'Formulario de finalização',
    quantitative: 'Quantitativo',
    infoText: 'Texto informativo',
    info: 'Informativo',
    altText: 'Texto alternativo',
    additionalInformation: 'Informações adicionais',
    sequenceInputHelp: 'Já existe um item com este valor',
    batchCreationWarning:
      'Atenção: Lotes criados não podem ser editados. Deseja continuar?',
    productTagHelp:
      'Tag não associada à árvore de elementos, o que impossibilita a produção automática deste produto através dessa tag no módulo de manufatura.',
    addElement: 'Adicionar elemento',
    noInstrumentsAvailable: 'Não há um instrumento associado a itens da lista',
    startOfStep: 'Início da etapa',
    startOfOrder: 'Início da ordem',
    componentsWithoutAppointmentInfo:
      'Atenção: Alguns componentes não possuem apontamento. Deseja continuar?',
    percentageExceededToleranceInfo:
      'A porcentagem de tolerância foi excedida. Por favor, adicione uma justificativa no campo de comentários.',
    percentageExceededToleranceInfoBlockSaveButton:
      'A porcentagem de tolerância foi excedida, com isso, o apontamento não poderá ser realizado.',
    reverseStepItemNoteInfo:
      'Toda a quantidade apontada será estornada e não será possível desfazer, deseja continuar?',
    itemsWithoutInstrument: 'Há itens verificados sem instrumento selecionado',
    samples: 'Amostras',
    fixedRange: 'Faixa fixa',
    minRange: 'Faixa mínima',
    maxRange: 'Faixa máxima',
    normalSampling: 'Amostragem normal',
    reinspectionSampling: 'Amostragem reinspeção',
    range: 'Faixa',
    needToInformRange: 'Necessário informar a faixa',
    approvedItems: 'Itens aprovados: ',
    disapprovedItems: 'Itens reprovados: ',
    pendingItems: 'Itens pendentes: ',
    useSample: 'Usar amostragem',
    linkTo: 'Vincular a',
    dependency: 'Dependência',
    dependencies: 'Dependências',
    addDependency: 'Adicionar dependência',
    triggerType: 'Tipo de gatilho',
    dependsOn: 'Depende de',
    ifResult: 'Se o resultado for',
    independent: 'Independente',
    emissionDate: 'Data de emissão',
    functions: 'Funções',
    allowCancelSrcOrder: 'Permitir cancelar OP',
    generateChecklist: 'Gerar lista',
    checkListWithoutSampleInfo:
      'Não foi encontrada amostragem cadastrada ou a quantidade informada está fora dos limites permitidos.',
    orderQuantity: 'Quantidade da ordem',
    center: 'Centro',
    dateCreation: 'Data de criação',
    timeStandard: 'Tempo STD',
    dependentLists: 'Listas dependentes',
    reinspectionBatch: 'Reinspeção',
    appointedQuantity: 'Quantidade apontada',
    samplingQuantity: 'Quantidade Amostragem',
    lotUsedIsDifferentInfo: 'Lote utilizado diferente do lote previsto',
    reviewers: 'Revisores',
    approvers: 'Aprovadores',
    revisionButtonInfo:
      'Apenas é possível criar revisões para listas de controle que já foram aprovadas ou que não possuam matriz de aprovação associada.',
    checkListItemClassification: 'Classificação dos itens',
    inspectionType: 'Tipo de inspeção',
    sampling: 'Amostragem',
    inspection100: 'Inspeção 100%',
    unique: 'Único',
    informationOfLevelIncomplete:
      'Alguns níveis podem não estar sendo exibidos pois não estão totalmente cadastrados.',
    inspection: 'Inspeção',
    quickAppointmentInfo:
      'Apontamento rápido é disponibilizado apenas para itens do tipo “Status de conformidade”',
    quickAppointment: 'Apontamento Rápido',
    appoint: 'Apontar',
    itemNotFound: 'Item não encontrado',
    searchingItems: 'Pesquisando itens...',
    searchCheckItem: 'Digite o código ou descrição',
    searchCheckItemInfo:
      'Digite o código ou a descrição do item para fazer a busca. A busca pode levar alguns instantes.',
    productBalanceUnavailable: 'Não há saldo disponível para este produto.',
    module: 'Módulo',
    noPendingDocuments: 'Não há documentos pendentes',
    checklistDescription: 'Descrição da lista de controle',
    limitBy: 'Limitar por',
    hasNotOkAppointments:
      'Há itens apontados em não conformidade, tem certeza que deseja continuar?',
    opNotFound: 'Ordem de produção não encontrada.',
    error: {
      orderDeleted:
        'Ordem de produção excluída, por favor retorne ao painel de ordens de produção e atualize a página.',
      orderNotFound:
        'Ordem de produção não encontrada, por favor retorne ao painel de ordens de produção e atualize a página.',
      lockByGeneratedStatus:
        "Não é possível realizar a ação solicitada porque o status da ordem de produção está definido como 'Gerado'. Por favor, atualize a página.",
      lockByCanceledStatus:
        "Não é possível realizar a ação solicitada porque o status da ordem de produção está definido como 'Cancelada'. Por favor, atualize a página.",
      lockByFinishedStatus:
        "Não é possível realizar a ação solicitada porque o status da ordem de produção está definido como 'Finalizada'. Por favor, atualize a página.",
      lockByRunningStatus:
        "Não é possível realizar a ação solicitada porque o status da ordem de produção está definido como 'Executando'. Por favor, atualize a página.",
      lockByInCompletionStatus:
        "Não é possível realizar a ação solicitada porque o status da ordem de produção está definido como 'Em finalização'. Por favor, atualize a página.",
    },
    checklistForm: {
      process: 'Processo',
      observations: 'Observações',
      classification: 'Classificação',
      generateByClassification: 'Gerar apenas itens críticos',
      moldNumber: 'Número do molde',
      drawerRevision: 'Revisão do desenho',
      addObservation: 'Adicionar observação',
      scripts: 'Roteiros',
      quality: 'Qualidade',
      production: 'Produção',
      sampling: 'Amostragem',
      unique: 'Único',
      all: 'Inspeção 100%',
      deleteComplementaryTableWarning: 'A tabela não pode ser excluída!',
      matrixButtons: {
        sendToReview: 'Enviar para revisão',
        sendToApprove: 'Enviar para aprovação',
        reproveReview: 'Reprovar revisão',
        reprove: 'Reprovar',
        approve: 'Aprovar',
        makeCurrent: 'Tornar vigente',
      },
      status: {
        created: 'Lista de controle criada',
        sentToReview: 'Enviado para revisão',
        reviewReproved: 'Revisão reprovada',
        sentToApproval: 'Enviado para aprovação',
        approved: 'Aprovado',
        reproved: 'Reprovado',
        current: 'Vigente',
        filed: 'Arquivado',
      },
    },
    checkListAppointment: {
      status: {
        created: 'Lista criada',
        archive: 'Lista arquivada',
        unarchive: 'Lista desarquivada',
      },
      error: {
        userRequired: 'Usuário e justificativa obrigatórios.',
        quantityNotAllowed:
          'Não foi possível desarquivar esta lista pois não há quantidade disponível o suficiente.',
        quantityUnavailable: 'Quantidade indisponível',
        quantityNotAllowedUpdatePage:
          'Quantidade maior que a permitida para geração da lista, por favor recarregue a página.',
        notPossibleUpdateArchivedChecklist:
          'Não foi possível salvar os apontamentos pois a lista está arquivada, por favor atualize a página.',
        checklistNotFound:
          'Lista de controle não encontrada, por favor recarregue a página.',
        checklistAlreadyArchived:
          'Lista de controle já arquivada, por favor recarregue a página.',
        checklistNotArchived:
          'Lista de controle não está arquivada, por favor recarregue a página.',
      },
      archiveConfirmMessage:
        'Você tem certeza de que deseja arquivar esta lista de controle?',
      archiveConfirmInfo: 'Seus apontamentos não serão mais contabilizados.',
      unarchiveConfirmMessage:
        'Você tem certeza de que deseja desarquivar esta lista de controle?',
      unarchiveConfirmInfo: 'Seus apontamentos serão contabilizados novamente.',
    },
    complementaryTable: {
      complementaryTable: 'Tabela complementar',
      selectComplementaryTable: 'Selecione uma tabela complementar',
      matrixButtons: {
        sendToReview: 'Enviar para revisão',
        sendToApprove: 'Enviar para aprovação',
        reproveReview: 'Reprovar revisão',
        reprove: 'Reprovar',
        approve: 'Aprovar',
        makeCurrent: 'Tornar vigente',
      },
      status: {
        created: 'Tabela complementar criada',
        sentToReview: 'Enviado para revisão',
        reviewReproved: 'Revisão reprovada',
        sentToApproval: 'Enviado para aprovação',
        approved: 'Aprovado',
        reproved: 'Reprovado',
        current: 'Vigente',
        filed: 'Arquivado',
      },
    },
    destOrderLog: {
      status: {
        start: 'Iniciar O.P',
        inCompletion: 'Enviar para finalização',
        cancel: 'Cancelar O.P',
        reopen: 'Reabrir O.P',
        ignoreChecklist: 'Ignorar lista de controle',
      },
    },
    createdBy: 'Elaborado por',
    reviewedBy: 'Revisado por',
    approvedBy: 'Aprovado por',
    effectiveFrom: 'Vigente em',
    changeHistory: 'Histórico de alteração',
    checklistOptionDisabledInfo: 'A lista de conrole não está vigente.',
    motiveGroup: {
      duplicatedCode:
        'Já existe outro grupo de motivo com esse código ({description}), por favor escolha outro código.',
      motive: {
        duplicatedCode:
          'Já existe outro motivo com esse código ({description}), por favor escolha outro código.',
      },
    },
    reports: {
      junk: {
        qtyPerMotive: 'Qtd X Motivo',
        qtyPerComponentPerMotive: 'Qtd X Componente X Motivo',
        qtyPerElementPerMotive: 'Qtd X Elemento X Motivo',
        qtyPerLotPerMotive: 'Qtd X Lote X Motivo',
        qtyPerProductionOrderPerMotive: 'Qtd X OP X Motivo',
        groupCode: 'Código do grupo',
        groupDescription: 'Descrição do grupo',
      },
    },
    inspectionLevel: {
      s: 'S',
      l: 'N',
      lotSize: 'Tamanho do lote',
      sampleSize: 'Tamanho da amostra',
      specialInspectionLevels: 'Níveis especiais de inspeção',
      generalInspectionLevels: 'Níveis gerais de inspeção',
      duplicatedInferiorLimit: 'Já existe um registro com esta faixa mínima',
      duplicatedSuperiorLimit: 'Já existe um registro com esta faixa máxima',
      s1: 'S1',
      s2: 'S2',
      s3: 'S3',
      s4: 'S4',
      l1: 'N1',
      l2: 'N2',
      l3: 'N3',
    },
    acceptableQualityLevel: {
      aql: 'NQA',
      ac: 'Aprova',
      re: 'Rejeita',
      sampleSize: 'Tamanho da amostra',
      duplicatedSample: 'Já existe um registro com esta amostragem',
      aql1: '0,01',
      aql2: '0,015',
      aql3: '0,025',
      aql4: '0,04',
      aql5: '0,065',
      aql6: '0,1',
      aql7: '0,15',
      aql8: '0,25',
      aql9: '0,4',
      aql10: '0,65',
      aql11: '1',
      aql12: '1,5',
      aql13: '2,5',
      aql14: '4',
      aql15: '6,5',
      aql16: '10',
      aql17: '15',
      aql18: '25',
      aql19: '40',
      aql20: '65',
      aql21: '100',
      aql22: '150',
      aql23: '250',
      aql24: '400',
      aql25: '650',
      aql26: '1000',
    },
  },
  operationalSecurity: {
    instrumentWithoutImpairment:
      'Alguns instrumentos estão inoperantes sem impairments criados',
    userNotInMatrix:
      'Apenas os solicitantes desse instrumento tem permissão para criar impairment',
    instrumentHasImpairment: 'Este instrumento possui um impairment em aberto',
    allInstruments: 'Todos os instrumentos',
    withImpairments: 'Com impairments criados',
    withoutImpairments: 'Sem impairments criados',
    riskLevel: 'Nível de risco',
    impairmentMaxDuration: 'Duração máxima permitida impairment',
    routineTestMaxDuration: 'Duração máxima permitida teste de rotina',
    intervalBeteweenImpairments: 'Intervalo mínimo entre dois impairments',
    routineTestRepeatTime: 'Tempo máximo para repetição de teste de rotina',
    frequencyMitigation: 'Frequência após mitigação',
    riskLevelWithoutMitigation: 'Nível do risco sem mitigação',
    riskLevelWithMitigation: 'Nível do risco após mitigação',
    externalCode: 'Código externo',
    externalDescription: 'Descrição externa',
    atuation: 'Atuação',
    logic: 'Lógico',
    physical: 'Físico',
    asynchronousInstruments: 'Instrumentos que não pode rodar simultaneamente',
    isRoutineTest: 'Teste de rotina?',
    testPeriodicity: 'Periodicidade do teste',
    weekly: 'Semanal',
    biweekly: 'Quinzenal',
    monthly: 'Mensal',
    semiannual: 'Semestral',
    yearly: 'Anual',
    criticalInstrumentTag: 'Tag vinculada com o instrumento crítico',
    controlLoopTag: 'Tag vinculada com a malha de controle',
    interlockTag: 'Tag vinculada com o intertravamento',
    requesters: 'Solicitantes',
    requestersAndPerformers: 'Solicitantes e executantes',
    approvers: 'Aprovadores',
    solicitationForm: 'Formulário de solicitação',
    createdAt: 'Data de criação da solicitação',
    observations: 'Observações',
    addImpairment: 'Adicionar impairment',
    lastTest: 'Último teste',
    tests: 'Testes realizados',
    impairmentWithMitigation:
      'Somente é possível adicionar impairment para esse instrumento se houver medida de mitigação',
    requesterPermission:
      'Apenas os solicitantes desse instrumento tem permissão para criar teste de rotina',
    unfinishedRoutineTest:
      'Já existe um teste em andamento para este instrumento',
    onlineMonitoring: 'Monitoramento online',
    withoutMitigation: 'Sem mitigação',
    generatingFile: 'Gerando arquivo...',
    impairment: {
      impairment: 'impairment',
      mitigationMeasureQuestion: 'Será implantada medida de mitigação?',
      routineTestMitigation: 'Será utilizada alguma medida de mitigação?',
      impairmentJustification: 'Justificativa para o impairment',
      minimumTimeInformation:
        'O impairment não atende ao período mínimo requerido entre dois impairments de um mesmo instrumento. A solicitação será enviada para um nível mais alto da escala de aprovação. Deseja prosseguir?',
      concurrentInstrumentInformation:
        'O impairment não atende aos requisitos de simultaneidade de impairments. A solicitação será enviada para um nível mais alto da matriz de aprovação. Deseja prosseguir?',
      concurrentInstruments: 'Instrumentos simultâneos',
      risks: 'Riscos',
      mitigationMeasure: 'Medida de mitigação',
      characterLimit: 'Limite de caracteres: 2000',
      solicitationDate: 'Data da solicitação',
      restoreDate: 'Data de restabelecimento',
      requester: 'Solicitante',
      approver: 'Aprovador',
      approvalStatus: 'Status de aprovação',
      executionStatus: 'Status de execução',
      solicitation: 'Solicitação',
      reproveJustification: 'Justificativa para reprovação',
      approveSolicitation: 'Aprovar solicitação',
      execution: 'Execução',
      instrument: 'Instrumento',
      approved: 'Aprovado',
      waiting: 'Aguardando',
      waitingExtension: 'Aguardando ampliação',
      processing: 'Operando',
      disapproved: 'Reprovado',
      routineTest: 'Teste de rotina',
      late: 'Desativado/Atrasado',
      onTime: 'Desativado/No prazo',
      approvedAt: 'Data de aprovação',
      disapprovedAt: 'Data de reprovação',
      approvedDisapproved: 'Data de aprovação/reprovação',
      executed: 'Executado',
      notExecuted: 'Não Executado',
      tested: 'Testado',
      notTested: 'Não testado',
      executedAt: 'Data de execução',
      restorationLimit: 'Data para restabelecimento',
      instrumentState: 'Status atual do Instrumento',
      durationExtensionForm: 'Formulário de ampliação de duração',
      invalidDurationMessage:
        'A data precisa ser maior que a data atual para restabelecimento',
      extendTo: 'Ampliar data limite para',
      instrumentOperatingStatus: 'Status de operação do instrumento',
      controlLoopStatus: 'Status da malha de controle',
      interlockStatus: 'Status intertravamento',
      inoperative: 'Inoperante',
      operant: 'Operante',
      manual: 'Manual',
      automatic: 'Automático',
      disabled: 'Desabilitado',
      able: 'Habilitado',
      concurrentLevelIncrease: 'Aumento de nível devido a simultaneidade',
      maxTimeRequestLevelIncrease:
        'Aumento de nível devido a ampliação de duração',
      currentLevel: 'Nível atual',
    },
    restoration: {
      beenRestored: 'Instrumento foi restabelecido?',
      restoredAt: 'Data de restabelecimento',
      restoration: 'Restabelecimento',
      restored: 'Restabelecido',
      notRestored: 'Não restabelecido',
      waiting: 'Aguardando',
    },
    execution: {
      date: 'Data',
      wasExecuted: 'O impairment foi executado?',
      executedAt: 'Data de execução',
    },
    routineTest: {
      testJustification: 'Justificativa para o teste',
      testMaxDuration: 'Duração máxima permitida para o teste',
      routineTests: 'Testes de rotina',
      routineTest: 'Teste de rotina',
      routineTestQuestion: 'Foi realizado teste de rotina?',
      impairmentDuringTestQuestion:
        'Será realizado o impairment durante o teste?',
      restorationQuestion: 'O SCI foi restabelecido após o teste?',
      successfulQuestion: 'O teste foi bem sucedido?',
      repeatQuestion: 'O teste precisará ser repetido?',
      instrumentPassedQuestion:
        'O instrumento atendeu satisfatoriamente ao teste?',
      testLimitDate: 'Data limite para o teste',
      repeatTestLimit: 'Você tem {time} para repetir o teste',
      completedOk: 'Concluído - no prazo',
      completedLate: 'Concluído - atrasado',
      waitingOk: 'Aguardando - no prazo',
      waitingLate: 'Aguardando - atrasado',
    },
  },
  managementOfChanges: {
    noAreaAvailable: 'Não há áreas cadastradas',
    ssma: 'SSMA',
    engineering: 'Engenharia',
    industry: 'Área Industrial',
    mocNumber: 'Número da MOC',
    area: 'Área',
    solicitation: 'Solicitação',
    requester: 'Solicitante',
    standardArea: 'Área Padrão',
    criticalArea: 'Área Crítica',
    addSolicitation: 'Adicionar solicitação',
    nameOfMOC: 'Nome da MOC',
    type: 'Tipo',
    model: 'Modelo',
    emergency: 'Emergencial',
    technique: 'Técnica',
    default: 'Padrão',
    temporary: 'Temporária',
    deadline: 'Prazo final',
    currentModelDescription: 'Descrição do modelo atual',
    changeDescription: 'Descrição da mudança proposta',
    mocSolicitation: 'Solicitação de MOC',
    riskAssessment: 'Avaliação de riscos',
    riskList: 'Lista de Riscos',
    risk: 'Risco',
    probability: 'Probabilidade',
    comments: 'Comentários',
    notApplicable: 'n/a',
    actionPlan: {
      title: 'Plano de ação',
      progressStatus: 'Status de andamento',
      deadlineStatus: 'Status do prazo',
      executed: 'Concluído',
      waiting: 'Aguardando',
    },
    additionalInformation: 'Informações adicionais',
    isCriticalArea: 'Área da mudança solicitada',
    responsibleArea: 'Área responsável',
    responsibleUser: 'Usuário responsável',
    initialDate: 'Data de início',
    durationInDays: 'Duração em dias',
    expectedDate: 'Data prevista',
    endDate: 'Data conclusão',
    observation: 'Observação',
    approvalSector: {
      ssma: 'Formulário de aprovação SSMA',
      engineering: 'Formulário de aprovação engenharia',
      industrialArea: 'Formulário de aprovação área industrial',
    },
    approver: 'Aprovador',
    authorizer: 'Autorizador',
    approved: 'Aprovado',
    waiting: 'Aguardando',
    disapproved: 'Reprovado',
    onTime: 'No prazo',
    late: 'Atrasado',
    executed: 'Executado',
    waitingExecution: 'Aguardando execução',
    execution: 'Execução',
    restoration: 'Restabelecimento',
    changeWasExecuted: 'A mudança foi executada?',
    changeWasRestored: 'A mudança foi restabelecida?',
    approvals: 'Aprovações',
    finalStatus: 'Status final',
    changeImplementation: 'Implementação da MOC',
    restored: 'Restabelecida',
    notRestored: 'Não restabelecida',
    notStarted: 'Não iniciado',
    inProgress: 'Em andamento',
    actionPlanStatus: 'Status do plano de ação',
    changeStatus: 'Status geral da MOC',
    waitingApproval: 'Aguardando aprovação',
    waitingRestoration: 'Aguardando restabelecimento',
    notExecuted: 'Não executado',
    restorationOnTime: 'Restabelecido no prazo',
    restorationLate: 'Restabelecido em atraso',
    riskLegend: '5 = muito provável; 1 = pouco provável; n/a = não aplica',
    maxLength200: 'Limite caracteres é 200',
    maxLength2000: 'Limite caracteres é 2000',
    cancelMoc: 'Cancelar MOC',
    canceledMoc: 'MOC cancelada',
    canceled: 'Cancelada',
    cancelConfirmation: 'Tem certeza que deseja cancelar essa MOC?',
    deActivateConfirmation:
      'Desativar fará com que esta tag não seja considerada',
    activateConfirmation: 'Ativar fará com que esta tag seja considerada',
    solicitationDate: 'Data de solicitação',
    mocPerformer: 'Executante da MOC',
    restorationPerformer: 'Executante do restabelecimento',
    approvers: 'Aprovadores',
  },
  scale: {
    ticketNumber: 'Nº ticket',
    dateEntry: 'Data de entrada',
    dateOut: 'Data de saída',
    weight: 'Peso líquido',
    board: 'Placa',
    inputCode: 'Código do insumo',
    input: 'Insumo',
    provider: 'Fornecedor',
    truck: 'Caminhão',
    conveyer: 'Transportador',
    nfeWeight: 'Peso NFE',
    nf: 'Nota fiscal',
    filterByDate: 'Filtro por data',
  },
  analytics: {
    comparativeBetweenPeriods: {
      skew: 'Assimetria',
      kurtosis: 'Curtose',
      shapiro: 'Valor p (teste normalidade shapiro-wilk)',
      varianceGlobal: 'Variância global',
      standardDeviationGlobal: 'Desvio padrão global',
      addGroup: 'Adicionar grupo',
      enterTitle: 'Insira um título para o grupo',
      overlaid: 'Sobreposto',
      parameter: 'Parâmetro',
      groupOne: 'Grupo 1',
    },
    correlation: {
      title: 'Correlação',
      regressionLine: 'Linha de regressão',
      absoluteValue: 'Valor absoluto',
      a: 'A',
    },
    eventManager: {
      addedEvent:
        'Evento adicionado na fila de processamento, isso pode levar alguns minutos!',
      remakeError: 'Houve um erro ao recriar evento, tente novamente!',
      remakeSucess: 'Evento recriado com sucesso!',
      selectFrame: 'Selecione um evento com dados para gerar o gráfico',
      recreateEvents: 'Você tem certeza que deseja recriar os eventos?',
      recreate: 'Recriar',
      open: 'Aberto',
      close: 'Fechado',
      canceled: 'Anulado',
      moreInformation: 'Selecione um evento para ver mais informações',
      closingDate: 'Data de encerramento',
      insertType: 'Tipo de inserção',
      auto: 'Automático',
      manual: 'Manual',
      alertCalendarActive:
        'O período visualizado possui calendário ativo, portanto alguns eventos podem não estar sendo exibidos.',
      showCalendar: 'Visualizar calendário',
      divided: 'Dividido',
      openEventDescription:
        'A data/hora permitida de finalização é a hora atual, mas pode haver atrasos na identificação automática do fim do evento, fazendo com que a hora real de finalização seja divergente à hora selecionada quando o evento for processado',
      configuration: {
        itemCode: 'Código do item',
        itemDescription: 'Descrição do item',
        selectAlert: 'Selecione um alerta',
        rangeIn: 'Faixa (DENTRO)',
        rangeOut: 'Faixa (FORA)',
        maxTimeExceeded: 'Tempo máximo excedido',
        equipmentManagement: 'Gestão de equipamento',
        active: 'Ativo',
        preview: 'Prévia',
        conditionsStart: 'Condições - início',
        conditionsEnd: 'Condições - fim',
        advancedOptions: 'Opções avançadas',
        time: 'Tempo',
        minDuration: 'Tempo mínimo de duração',
        inSeconds: 'Em segundos',
        seconds: 'segundos',
        minInterval: 'Intervalo mínimo entre eventos',
        maxTimeMicroStop: 'Tempo máximo de micro parada',
        executionInterval: 'Intervalo de execução',
        lastExecution: 'Última execução',
        minutes: 'minutos',
        standardMicroStop: 'Motivo padrão micro parada',
        complementaryTypes: 'Tipos complementares',
        motivesGroup: 'Grupo de Motivos',
        selectItem: 'Selecione um item',
        selectEvents: 'Selecione os eventos',
        typeHelp:
          'Os itens complementares associados diretamente ao Evento aparecem no Apontamento de Paradas independente do motivo selecionado.',
        referenceValueHelp:
          'Valor de referência para verificar o próximo ponto após o início do evento.',
        breakDay: 'Finalizar evento ao fim do dia',
        motives: {
          planned: 'Planejado',
          motiveCode: 'Código do motivo',
          motiveCodeDuplicate: 'Código do motivo já em uso',
          motiveDescription: 'Descrição do motivo',
          needApprove: 'Necessita aprovação',
        },
        permissions: 'Permissões',
        relatedFrames: 'Eventos Relacionados',
        relatedFramesTime: 'Diferença em segundos dos eventos',
        userPermissions: 'Permissão para usuários',
        enableCancelStopping: 'Permitir anular paradas',
        alreadyAssociatedAutomaticTag:
          'Já existe um grupo de apontamento automático associado',
      },
    },
    operator: {
      title: 'Operador',
      shiftScheduling: 'Agendamento de turnos',
      noneOperator: 'Nenhum operador agendado para este período',
      fillDates: 'Replicar para próximas datas',
      duplicatedScheduleMessage:
        'Impossível continuar, agendamento já cadastrado na unidade',
      showGoal: 'Exibir Meta',
      hideGoal: 'Ocultar Meta',
      column: {
        goal: 'Meta',
      },
    },
    report: {
      diary: 'Diário',
      weekly: 'Semanal',
      monthly: 'Mensal',
      accumulated: 'Acumulado',
    },
    process: {
      recalculateQuestion: 'Deseja recalcular os pontos convertidos?',
      recalculate: 'Recalcular',
      reconsolidateQuestion: 'Deseja recalcular os pontos consolidados?',
      reconsolidate: 'Reconsolidar',
    },
  },
  rootCause: {
    statusHistory: 'Histórico do status',
    active: 'Ativo',
    inactive: 'Inativo',
    rule: 'Regra',
    maxTimeStatus: 'Nenhum preenchimento em {time} horas',
    removed: 'Removido com sucesso!',
    effectNotFound: 'Não foi possível encontrar esse efeito',
    fiveWTwoH: '5W, 2H',
    deleteConfirmation: 'Tem certeza que deseja deletar esse problema/efeito?',
    causeConfirmation: 'Tem certeza que deseja cancelar essa causa?',
    removeCauseConfirmation: 'Tem certeza que deseja remover essa causa?',
    removeProblemEffect: 'Remover este problema/efeito',
    causeError:
      'Houve um problema ao salvar a causa, por favor, tente novamente...',
    insertCause: 'Inserir causa',
    waitingConclusion: 'Aguardando conclusão',
    waitingVerification: 'Aguardando verificação',
    pendingVerification: 'Pendente de verificação',
    checkedEffective: 'Verificada - efetiva',
    checkedIneffective: 'Verificada - inefetiva',
    efficiencyError: 'Erro ao tentar salvar a eficiência',
    attachmentError: 'Erro ao tentar excluir o anexo da eficiência',
    late: 'Atrasado',
    onTime: 'No prazo',
    efficiencyAction: 'Eficácia da ação',
    responsable: 'Responsável',
    examiner: 'Verificador',
    change: 'Alterar',
    status: 'Status de verificação',
    approvalStatus: 'Status de aprovação',
    verificationDate: 'Data de verificação',
    selectDate: 'Selecione a data',
    commentary: 'Comentário',
    conclusionDate: 'Data de conclusão',
    writeCommentary: 'Escreva um comentário sobre a análise',
    editActionNotAllowed:
      'Não é permitido editar uma ação já concluída ou cancelada',
    attachments: 'Anexos',
    saveAvaliation: 'Salvar avaliação',
    needStatus: 'Necessário informar o status junto da data de verificação',
    canceled: 'Cancelada',
    causeCanceled: 'Causa cancelada',
    causeCanceledDescription:
      'Esta causa foi cancelada, as atividades desta causa serão suspensas',
    noneRootCause: 'Não há causas raiz',
    askApproval: 'Solicite aprovação',
    completed: 'Concluído',
    pending: 'Pendente',
    open: 'Abrir',
    removeActionConfirmation: 'Tem certeza que deseja remover essa ação?',
    duplicateActionConfirmation: 'Tem certeza que deseja duplicar essa ação?',
    onlyPendingStatus:
      'Só é possível duplicar a ação se o status estiver pendente',
    situation: 'Situação',
    manager: 'Gestor',
    applicator: 'Aplicador',
    deadline: 'Data limite',
    why: 'Vínculo com porque',
    listActionsError:
      'Houve um problema ao tentar listar as ações, por favor, tente novamente',
    actionPlan: 'Plano de ação',
    addAction: 'Adicionar ação',
    writeTitle: 'Por favor, escreva um título para esta ação',
    actionDetails: 'Detalhes da ação',
    whatToDo: 'O que deve ser feito',
    whyToDo: 'Porque deve ser feito',
    howToDo: 'Como deve ser feito',
    assistance: 'Acompanhamento',
    whysSave: 'Porques salvos com sucesso',
    whys: 'Porques',
    emptyWhys: 'Não é permitido salvar com porques vazios',
    origin: 'Origem',
    cause: 'Causa',
    causes: 'Causas',
    addCause: 'Adicionar causa',
    classification: 'Classificação',
    gravity: 'Gravidade',
    urgency: 'Urgência',
    tendency: 'Tendência',
    searchUser: 'Pesquisar usuário',
    allWithFilter: 'Todos (com filtro)',
    lastFive: 'Últimos 5 cadastrados (sem filtro)',
    successEffect: 'Efeito adicionado com sucesso!',
    errorNewEffect: 'Erro ao tentar criar um novo efeito',
    errorRequestEffect:
      'Houve um problema ao solicitar os efeitos, por favor, tente novamente',
    addEffect: 'Adicionar efeito',
    analysisStatus: 'Status da análise',
    byDescription: 'Por descrição',
    filterByDescription: 'Filtrar por descrição',
    effectForm: 'Formulário de efeito',
    effectDescription: 'Descrição do efeito',
    typeUser: 'Digite o usuário',
    addExaminer: 'Adicionar verificador',
    errorListActions:
      'Houve um problema ao tentar listar as ações, por favor, tente novamente',
    registerIn: 'Cadastrado em',
    access: 'Acessar',
    efficiencySituation: 'Status geral da análise',
    noDate: 'Sem data definida',
    addAttachment: 'Clique para adicionar anexo',
    file: 'arquivo',
    tagsGroup: 'Grupo de tags',
    generateAnalysis: 'Gerar análise',
    generateRootCauseAnalysis: 'Gerar análise de causa raiz',
    errorGetEffects:
      'Erro ao tentar consultar os efeitos cadastrados, por favor, tente novamente',
    createdAt: 'Causa raiz criada em:',
    goToRootCause: 'Ir para causa raiz',
    verificationDeadline: 'Prazo para verificação',
    sendToApprove: 'Enviar para aprovação',
    alreadySentToApprove: 'Enviado para aprovação',
    approvedApproval: 'Aprovado',
    disapprovedApproval: 'Reprovado',
    canceledStatus: 'Cancelado',
    notStartedOk: 'Não iniciado - no prazo',
    notStartedLate: 'Não iniciado - atrasado',
    inProgressOk: 'Em andamento - no prazo',
    inProgressLate: 'Em andamento - atrasado',
    pendingVerificationOk: 'Pendente verificação - no prazo',
    pendingVerificationLate: 'Pendente verificação - atrasado',
    completedOk: 'Concluído - no prazo',
    completedLate: 'Concluído - atrasado',
    ishikawa: 'Ishikawa',
    efficiency: 'Eficácia',
    extremelySerious: 'Extremamente grave',
    verySerious: 'Muito grave',
    serious: 'Grave',
    lowSerious: 'Pouco grave',
    notSerious: 'Sem gravidade',
    worseQuickly: 'Irá piorar rapidamente',
    worseInShortPeriod: 'Irá piorar a curto prazo',
    worseInMediumPeriod: 'Irá piorar a médio prazo',
    worseInLongPeriod: 'Irá piorar a longo prazo',
    notChange: 'Não irá mudar',
    needActionNow: 'Necessidade de ação imediata',
    veryUrgent: 'Muito urgente',
    urgentShortPeriod: 'Urgente, atenção no curto prazo',
    lowUrgent: 'Pouco urgente',
    canWait: 'Pode esperar',
    machine: 'Máquina',
    environment: 'Meio ambiente',
    method: 'Método',
    measure: 'Medida',
    labor: 'Mão de obra',
    feedStock: 'Matéria prima',
    approved: 'Aprovada',
    disapproved: 'Reprovada',
    waitingApproval: 'Aguardando Aprovação',
    indicator: 'Indicador',
    selectUser: 'Selecione o usuário',
    mostRecent: 'Mais recentes:',
    formatMustBe: 'Formato deve ser HH:mm ou HHH:mm',
    description: 'Descrição:',
    tag: 'Tag:',
    useActionPlanLimits: 'Utilizar limites do plano de ação',
    allowSimultaneousAnalyzes: 'Permitir gerar análises simultâneas',
    pointsOutOfBounds: 'Número de pontos consecutivos fora do limite',
    dateFieldOrientation: 'Utilizar a orientação das datas na vertical',
    defaultAggregation: 'Agregação padrão',
    insufficientPoints:
      'Quantidade insuficiente de pontos fora dos limites para geração de análise',
    needToSave:
      'Para poder adicionar porquês "filhos", primeiramente você deve salvar os porques "pais"',
  },
  dashboard: {
    maxLengthSubview:
      'Não é possível adicionar mais widgets a esta aba, pois o limite de widgets foi atingido',
    maxLengthWidget:
      'Não é possível adicionar mais componentes a este widget, pois o limite de componentes foi atingido',
    treemapEmptyDescription:
      'Nenhum ponto fora dos limites de especificação. Todos os dados estão dentro dos parâmetros normais.',
    exhibitionType: 'Opção de exibição dos comparativos',
    exhibitionTypeInfo:
      'Para habilitar esta configuração, é necessário que o gráfico seja do tipo “linha” e que tenha alguma das opções de exibição de limites ou meta ativa',
    redCircle: 'Círculos vermelhos',
    redLine: 'Linha vermelha',
    noExhibition: 'Sem exibição',
    redCircleInfo:
      'O ponto fora dos limites ou meta será exibido através de uma representação circular vermelha',
    redLineInfo:
      'Os pontos fora dos limites ou meta serão exibidos através de uma linha contínua vermelha',
    notExistsPoints: 'Não existe pontos',
    tabIsSelected:
      'A aba selecionada é do tipo “{type}”, apenas abas do mesmo tipo podem ser selecionadas',
    selectTabs: 'Selecione as abas a serem exportadas',
    exportingDashboard:
      'Exportando, isso pode demorar alguns minutos. Por favor, aguarde',
    moreThanOneTabType:
      'Há mais de um tipo diferente de aba, portanto não é possível exportar o dashboard completo',
    exportDashboard: 'Exportar dashboard para PDF',
    onlySpcTags: 'Apenas tags pertencentes ao CEP são exibidas',
    useGoal: 'Utilizar meta',
    fixIndicatorColumn: 'Fixar indicador',
    deleteDashboard: 'O dashboard foi excluído',
    errorDeleteDashboard: 'Houve um problema ao tentar excluir o dashboard',
    deleteDashboardConfirmation:
      'Você tem certeza que deseja excluir esse dashboard?',
    fullscreen: 'Tela cheia',
    exitFullscreen: 'Sair da tela cheia',
    reproduction: 'Reproduzir',
    exitReproduction: 'Sair do modo TV',
    startTvMode: 'Modo TV - dashboard',
    startTvModeTab: 'Modo TV - tab atual',
    tvMode: 'Modo TV',
    editDashboard: 'Editar dashboard',
    excludeDashboard: 'Excluir dashboard',
    unauthorized: 'Desculpe, você não está autorizado a acessar esta página',
    cannotEditType:
      'Não é permitido alterar o tipo do dashboard quando há abas criadas',
    initialPage: 'Página inicial',
    daysReleased: 'Dias liberados',
    currentDay: 'Dia atual',
    dashboardForm: 'Formulário dashboard',
    writeDescription: 'Por favor, digite a descrição',
    switchTabTimer: 'Tempo de troca da aba',
    switchTabTimerDescription:
      'Tempo em segundos que cada tab ficará sendo exibida no modo TV',
    autoLoadingDescription:
      'Carrega os gráficos de todas as tabs ao abrir o dashboard',
    autoLoading: 'Carregar automaticamente',
    typeDescription: 'Tipo de Dashboard',
    typeDefaultLabel: 'Dashboard Padrão',
    typeAppLabel: 'Dashboard APP',
    typeHmiLabel: 'Dashboard IHM',
    userDashboardAccess: 'Usuários que terão acesso ao dashboard',
    lastUpdate: 'Última atualização',
    ll: 'LI',
    ul: 'LS',
    upper: 'Superior',
    lower: 'Inferior',
    goal: 'Meta',
    componentDeleted: 'O componente foi excluído',
    componentDeleteError: 'Houve um problema ao tentar excluir o componente',
    updateData: 'Atualizar dados',
    editComponent: 'Editar componente',
    deleteComponent: 'Excluir componente',
    noIndicators: 'Sem indicadores',
    checkSettings:
      'Houve algum problema, por favor, verifique as configurações',
    zIndexUpButton: 'Trazer para frente',
    zIndexDownButton: 'Enviar para trás',
    alignTop: 'Alinhar pela parte superior',
    alignLeft: 'Alinhar pela esquerda',
    alignBottom: 'Alinhar pela parte inferior',
    alignRight: 'Alinhar pela direita',
    alignCenterVertical: 'Centralizar verticalmente',
    alignCenterHorizontal: 'Centralizar horizontalmente',
    link: 'Link',
    componentType: 'Tipo do componente',
    subTitle: 'Subtítulo',
    headerColor: 'Cor do cabeçalho',
    prefix: 'Prefixo',
    suffix: 'Sufixo',
    refreshDelay: 'Tempo de atualização dos dados',
    refreshDelayDescription:
      'Tempo em segundos do intervalo de atualização dos dados no modo TV',
    direction: 'Direção',
    aggregationPeriod: 'Período de agregação',
    aggregationExibition: 'Período de exibição',
    dateFormat: 'Formato da data',
    updateDate: 'Data de atualização',
    legend: 'Legenda',
    serieAmplitude: 'Amplitude da série',
    goalPercentage: 'Tolerância da meta',
    goalPercentageInfo:
      'Caso a meta seja entre faixas, a porcentagem será feita com base na diferença das faixas',
    currentShift: 'Turno atual',
    currentWeek: 'Semana atual',
    currentMonth: 'Mês atual',
    currentHarvest: 'Safra atual',
    currentYear: 'Ano atual',
    last: 'Últimos',
    minutes: 'Minutos',
    hours: 'Horas',
    days: 'Dias',
    useCompleteAmplitude: 'Usar amplitude completa',
    useFullHour: 'Usar hora cheia',
    componentForm: 'Formulário componente',
    advancedOptions: 'Opções avançadas',
    unit: 'Unidade',
    selectOtherUnitData: 'Selecione outra unidade para usar os dados dela',
    events: 'Eventos',
    lowerChartLimit: 'Limite inferior do gráfico',
    upperChartLimit: 'Limite superior do gráfico',
    bulletChartOrderInfo:
      'No caso de valores iguais, será considerada a ordenação pela legenda, código ou descrição da TAG',
    loadingFile: 'Gerando arquivo...',
    isRequired: 'é obrigatório',
    ganttNewDataTitle: 'abc',
    colorCode: 'Código da cor',
    colorTypeLabel: 'Tipo',
    initialValue: 'Valor inicial',
    finalValue: 'Valor final',
    color: 'Cor',
    goalColor: 'Cor ao atingir a meta',
    cancelEditConfirmation: 'Deseja cancelar a edição?',
    addColor: 'Adicionar cor',
    limitsColor: 'Cor dos limites',
    current: 'Atual',
    indicator: 'Indicador',
    calculation: 'Cálculo',
    tagsListFromUnit: 'Esta lista contém tags da unidade',
    logbookListFromUnit: 'Essa lista contém grupos de anotações da unidade',
    valuesToDisplay: 'Valores a serem exibidos',
    aggregation: 'Agregação',
    serieValues: 'Valores da série',
    compareWith: 'Comparar com',
    none: 'Nenhum',
    graphType: 'Tipo do gráfico',
    textFormat: 'Formato do texto',
    textSize: 'Tamanho do texto',
    displaySize: 'Tamanho da tela',
    limitSize: 'Tamanho do limite',
    colors: 'Cores',
    backgroundColor: 'Cor de fundo',
    showUpperLimit: 'Exibe limite superior',
    showLowerLimit: 'Exibe limite inferior',
    showGoal: 'Exibe meta',
    showLabelGoal: 'Exibir valor da meta',
    showValue: 'Exibe valor',
    order: 'Ordem',
    indicatorExcluded: 'Indicador foi excluído',
    indicators: 'Indicadores',
    addIndicator: 'Adicionar indicador',
    indicatorForm: 'Formulário indicador',
    inOutLimits: 'Dentro/fora dos limites',
    inOutReference: 'Dentro/fora da referência',
    out: 'Fora',
    in: 'Dentro',
    motiveGroup: 'Grupo de motivos',
    yAxisValue: 'Valor eixo y',
    hoursStop: 'Horas paradas',
    stop: 'Parada',
    available: 'Disponível',
    showAccumulated: 'Exibir acumulado',
    importing: 'Importando...',
    widgetImported: 'Widget importado com sucesso!',
    errorImportingFile:
      'Houve um error na importação, por favor, revise o arquivo e tente novamente',
    widgetDeleted: 'O widget foi excluído',
    errorDeletingWidget: 'Houve um problema ao tentar excluir o widget',
    widgetPermission:
      'Este widget só é visível para usuários permitidos a gerenciar o dashboard',
    addWidget: 'Adicionar widget',
    importWidget: 'Importar widget',
    tabForm: 'Formulário tab',
    allDays: 'Todos os dias',
    tabImported: 'Tab importada com sucesso!',
    tabDeleted: 'A tab foi excluída',
    errorDeletingTab: 'Houve um problema ao tentar excluir a tab',
    addTab: 'Adicionar tab',
    importTab: 'Importar tab',
    exportTab: 'Exportar tab',
    editTab: 'Editar tab',
    deleteTab: 'Excluir tab',
    exportPdf: 'Exportar para PDF',
    noneTabs: 'Não há tabs criadas no dashboard',
    createTab: 'Criar tab',
    addComponent: 'Adicionar componente',
    exportWidget: 'Exportar widget',
    editWidget: 'Editar widget',
    deleteWidget: 'Excluir widget',
    widgetForm: 'Formulário widget',
    columnDataCalc: 'Coluna com dados calculados',
    tagDescriptionWidth: 'Largura da coluna de referência',
    meanAndSum: 'Média e soma',
    logbookGroup: 'Grupo de anotações',
    filterByTitle: 'Filtrar pelo título',
    openDashboard: 'Abrir dashboard',
    indicatorType: 'Tipo do indicador',
    joinEvents: 'Mesclar dados dos indicadores',
    dashboardPermission:
      'Este card só é visível para usuários permitidos a gerenciar o dashboard',
    icon: 'Ícone',
    dynamicIndicator: 'Indicador dinâmico',
    dynamicIndicatorDescription:
      'O indicador dinâmico serve para mudar os indicadores conforme seu valor',
    emissionDate: 'Data de emissão',
    referenceDate: 'Data de referência',
    showAvailable: 'Exibir (Em operação)',
    showLegendDescription: 'Exibir descrição na legenda',
    showAllMotives: 'Exibir todos os motivos na legenda',
    specificationLimit: 'Limites de especificação',
    actionLimit: 'Limites de plano de ação',
    spc: 'CEP',
    reference: 'Referência',
    groupByCalculation: 'Necessário agrupar para tag cálculo',
    dynamicPeriod: 'Período dinâmico',
    externalAggregation: 'Agregação externa',
    externalAggregationHelp:
      'Ao habilitar será exibido um seletor de agregação no componente e por padrão será obedecido o agrupamento do indicador de sequência 1 ou do componente',
    showControlLimits: 'Exibe limites de controle (CEP)',
    controlLimits: 'Limites de controle (CEP)',
    zeroColor: 'Faixa de cor - 0%',
    fiftyColor: 'Faixa de cor - 50%',
    oneHundreadColor: 'Faixa de cor - 100%',
    controlLimitsInfo:
      'Limite inferior de controle: {lowerLimit} - limite superior de controle: {upperLimit}',
    specificationLimitsInfo:
      'Limite inferior de especificação: {lowerLimit} - limite superior de especificação: {upperLimit}',
    uniqueGoalInfo: 'Meta: {goal}',
    lowerUpperGoalInfo:
      'Meta inferior: {lowerGoal} - meta superior {upperGoal}',
    noneToCompare: 'Não há faixa vigente',
    colorType: {
      free: 'Livre',
      specificationLimit: 'Limite de especificação',
      goal: 'Meta',
    },
    componentTypes: {
      labels: {
        chart: 'Gráfico',
        pareto: 'Gestão de paradas',
        pizza: 'Gráfico de pizza',
        gauge: 'Gauge',
        solidGauge: 'Gauge gradiente',
        grid: 'Grid',
        imageConditional: 'Imagem condicional',
        staticImage: 'Imagem estática',
        text: 'Texto',
        gav: 'Gestão a vista',
        status: 'TAG status',
        gantt: 'Gantt',
        eventManager: 'Gestão de eventos',
        report: 'Relatório',
        managementCard: 'Gestão de desempenho',
        logbook: 'Anotações',
        bulletChart: 'Gráfico com marcadores',
        paretoChart: 'Gráfico de pareto',
        treemap: 'Mapa de árvore',
        operatorChart: 'Gráfico de análise de operadores',
        deviationManagement: 'Gestão de desvios',
      },
      descriptions: {
        chart:
          'Apresenta os pontos de dados da série temporal na forma de barras verticais ou linhas. O usuário deverá selecionar a amplitude da série e se serão exibidos dados agregados ou os pontos individuais da série',
        pareto:
          'Exibe o gráfico de pareto dos motivos apontados na cadeia de controle da parada selecionada. O usuário deverá selecionar a amplitude dos motivos apontados e a forma que os valores de horas paradas serão apresentados (percentual ou absoluto)',
        pizza:
          'Exibe o gráfico de pizza dos motivos apontados na cadeia de controle da parada selecionada. O usuário deverá selecionar a amplitude dos motivos apontados',
        gauge:
          "Exibe o indicador selecionado no formato de velocímetro, sendo necessário ao usuário definir os limites ideais de trabalho (região verde). O sistema calculará a zona de atenção' (região amarela) e a zona fora de especificação' (região vermelha)",
        solidGauge:
          "Exibe o indicador selecionado no formato de velocímetro, sendo que o componente irá passar pelas diferentes cores de seu gradiente conforme a definição de 'maior melhor' ou 'menor melhor' definido pelo usuário na configuração do indicador",
        grid: 'Exibe os valores do indicador dentro da amplitude selecionada pelo usuário no formato de tabela, sendo possível selecionar para exibir os pontos agregados em um período selecionado ou seus valores individuais',
        image:
          'Exibe uma imagem condicional inserida no sistema pelo usuário a partir do resultado de uma equação definida no indicador',
        staticImage:
          'Exibe uma imagem estática inserida pelo usuário. Utilizada para exibir imagens de equipamentos, logos ou objetos.',
        text: 'Exibe o resultado do indicador selecionado dentro da amplitude definida pelo usuário no formato de valor numérico, sendo possível também ao usuário inserir equações sem ter que definir novos indicadores',
        gav: 'Exibe o valor do indicador selecionado e o compara ao valor da meta, exibindo um sinal de positvo ou de negativo dependendo do resultado dessa combinação',
        status:
          "Exibe o valor do indicador selecionado e o compara ao valor dos limites de controle, definidos na configuração' do indicador, exibindo um sinal de positvo ou de negativo dependendo do resultado dessa combinação",
        gantt:
          'Exibe o gráfico de gantt, o qual é usado para ilustrar o avanço das diferentes etapas dos dados apresentados',
        eventManager:
          'Exibe o gráfico da cadeia de controle seleciona, mostrando os frames gerados dentro do período selecionado',
        report:
          'Exibe os valores agregados ou calculados dos indicadores selecionados nos períodos definidos nas configurações do relatório',
        managementCard:
          'Exibe o último valor do indicador e o compara aos limites definidos nas configurações do indicador. Além disso, exibe as porcentagens de tempo que cada turno atuou fora da faixa dos limites',
        logbook:
          'Exibe as anotações feitas no período definido para o grupo de anotações selecionado',
        bulletChart:
          'Os gráficos com marcadores permitem que os leitores comparem rapidamente um único valor com um valor alvo. O valor alvo é definido na configuração da meta da tag.',
        paretoChart:
          'O gráfico de pareto é um gráfico de colunas que ordena as frequências das ocorrências, da maior para a menor, permitindo a priorização dos problemas',
        treemap:
          'O mapa de árvore ordena as frequências das ocorrências, da maior para a menor, permitindo a priorização dos problemas',
        operatorChart:
          'O gráfico de operadores e tags exibe dados que relacionam tags aos operadores e seus turnos agendados. Esse gráfico visa mostrar a agregação de tags para operadores, dependendo do turno de trabalho',
        deviationManagement:
          'Exibe os desvios dos indicadores do controle estatístico de processo (CEP) dentro da amplitude selecionada pelo usuário no formato de tabela, sendo possível selecionar para exibir os pontos agregados em um período selecionado ou seus valores individuais',
      },
    },
  },
  accessControl: {
    register: 'Cadastro',
    operation: 'Operação',
    routine: 'Rotina',
    initialPage: 'Página Inicial',
    users: {
      login: 'Login',
      name: 'Nome',
      type: 'Tipo',
      adm: 'Administrador',
      active: 'Ativo',
      maxLengthExceeded: 'Tamanho máximo excedido',
      email: 'Email',
      insertValidEmail: 'Insira um email válido',
      accountType: 'Tipo de conta',
      activeUser: 'Usuário ativo',
      unblockUser: 'Desbloquear usuário',
      pngJpg: 'Você pode apenas enviar arquivos PNG/JPG!',
      lessTwoMb: 'A imagem deve ter menos de 2MB!',
      passwordExtra: 'Digite uma nova senha para alterar a atual',
      passwordCorrection: 'Digite uma nova senha para desbloquear o usuário',
      upload: 'Enviar',
      validatePassword: {
        minimumCaracters: 'A senha deve ter mais de 8 caracteres',
        upperCase: 'A senha deve ter no mínimo uma letra maiúscula',
        lowerCase: 'A senha deve ter no mínimo uma letra minúscula',
        number: 'A senha deve ter no mínimo um número',
        specialCaracter: 'A senha deve ter no mínimo um caractere especial',
      },
    },
  },
  calendar: {
    productiveHour: 'Hora Produtiva',
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
    sunday: 'Domingo',
    period: 'Período',
    startTime: 'Hora Inicial',
    endTime: 'Hora Final',
    weekDays: 'Dias da Semana',
  },
  dateHour: {
    dateAndHourSuccess: 'Horários e datas alterados com sucesso',
    shiftStart: 'Início do turno',
    endShift: 'Fim do turno',
    unitDateAndHourConfiguration:
      'Configuração de horários e data para a unidade',
    startDay: 'Hora de início do dia',
    selectHour: 'Selecionar hora',
    startWeek: 'Início da semana',
    selectDay: 'Selecione o dia',
    sunday: 'Domingo',
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado',
    harvestPeriod: 'Período da safra',
    timezone: 'Horário padrão',
    selectTimezone: 'Selecione o horário padrão',
    shifts: 'Turnos',
    calendar: 'Calendário',
    addShift: 'Adicionar turno',
    previousDay: 'Dia anterior',
    noProduction: 'Sem produção',
  },
  tags: {
    inactiveTag:
      'A tag está inativa pelo cadastro, por isso não é possível seleciona-la',
    inactivateConfirmation:
      'Ao inativar essa tag não será possível utiliza-la na plataforma. Tem certeza que deseja inativar?',
    divisionByZero: 'Divisão por zero',
    syntaxError: 'Erro de sintaxe',
    hasComments: 'O cálculo possui comentários',
    missingParentheses: 'O cálculo está faltando parênteses',
    hasFcm: 'O cálculo possui "fcm." ao invés de "tag."',
    missingQuotes: 'O cálculo está faltando aspas',
    invalidFunction: 'Função inválida',
    missingTags: 'O cálculo possui tags não existentes na planta: {tags}',
    missingPoints:
      'O cálculo possui tags que não tem pontos no período selecionado: {tags}',
    validated: 'O cálculo foi validado, resultado: {result}',
    validCalculation: 'O cálculo precisa ser validado',
    testConversion: 'Testar conversão',
    csvExample: 'Temp ABC',
    importTags: 'Importar tags',
    downloadExample: 'Baixar exemplo',
    advanced: 'Avançado',
    tagCodeDuplicated: 'Código duplicado na estrutura de importação',
    tagLimit: 'Limite de tags',
    limitExceeded: 'O limite de {limit} tags foi excedido em {exceed} tag(s)',
    duplication: 'Duplicação',
    dataInput: 'Entrada dos dados',
    inactive: 'Inativo',
    laboratory: 'Laboratório',
    sensor: 'Sensor',
    automation: 'Automação',
    conversion: 'Conversão',
    aggregationType: 'Tipo de agregação',
    calculation: 'Cálculo',
    validateCalculation: 'Validar cálculo',
    validate: 'Validar',
    format: 'Formatação',
    tagType: 'Tipo da tag',
    greaterBetter: 'Maior melhor',
    smallerBetter: 'Menor melhor',
    decimalPlaces: 'Casas decimais',
    tagFilter: 'Tag filtro',
    consolidateHour: 'Consolidar por hora',
    consolidateHourHelp:
      'Consolidação por hora da tag original de acordo com seu tipo de agregação e grava o valor consolidado em sua tag "_H" podendo ser oculta ou não.',
    consolidateDay: 'Consolidar por dia',
    consolidateDayHelp:
      'Consolidação por dia da tag original de acordo com seu tipo de agregação e grava o valor consolidado em sua tag "_D" podendo ser oculta ou não.',
    consolidateShift: 'Consolidar por turno',
    consolidateShiftHelp:
      'Consolidação por turno da tag original de acordo com seu tipo de agregação e grava o valor consolidado em sua tag "_T" podendo ser oculta ou não.',
    consolidateWeek: 'Consolidar por semana',
    consolidateWeekHelp:
      'Consolidação por semana da tag original de acordo com seu tipo de agregação e grava o valor consolidado em sua tag "_S" podendo ser oculta ou não.',
    consolidateMonth: 'Consolidar por mês',
    consolidateMonthHelp:
      'Consolidação por mês da tag original de acordo com seu tipo de agregação e grava o valor consolidado em sua tag "_M" podendo ser oculta ou não.',
    tagInterpolation: 'Tag interpolação',
    duplicate: 'Duplicar',
    calculationValidity: 'Vigência dos cálculos',
    defaultValidity: 'Vigência padrão',
    validity: 'Vigência',
    addNewValidity: 'Adicionar nova vigência',
    duplicatedValidityError:
      'Não é possivel inserir duas vigências iguais, a data desta vigência já é existente.',
    needToSaveTheTag:
      'Necessário salvar a tag para que as mudanças de vigência tenham efeito',
    conversionInterval: 'Intervalo de execução da conversão',
    actionPlanLimitInfo:
      'Limite que quando preenchido é utilizado pela ferramenta “Gerar Plano de Ação” do módulo Gestão de Plano de Ação para liberar o botão de geração de plano de ação.',
    especificationLimitInfo:
      'Limite utilizado para exibição em componentes do dashboard como gráfico, gauge, grid e gestão de desempenho. Quando não há o preenchimento do limite de plano de ação, também é utilizado para liberar o botão de geração de plano de ação.',
    tagDescription: {
      decimalPlaces: 'Número de casas decimais',
      upperFilter: 'Filtro superior',
      lowerFilter: 'Filtro inferior',
      lowerEspecificationLimit: 'Limite inferior de especificação',
      upperEspecificationLimit: 'Limite superior de especificação',
      lowerActionPlanLimit: 'Limite inferior de plano de ação',
      upperActionPlanLimit: 'Limite superior de plano de ação',
      tagType: 'Tipo da tag (menor melhor (<), maior melhor (>)),',
      aggregationType: 'Tipo de agregação (AVERAGE, SUM, DIFF, CALC)',
      calcFunction: 'Função cálculo (caso o tipo de agregação for "CALC")',
      dataInput: 'Entrada dos dados (sensor, manual, calculation)',
      lowerValidLimit: 'Limite válido inferior',
      upperValidLimit: 'Limite válido superior',
      calculationInput:
        'Cálculo da conversão (caso o tipo de entrada de dados for "calculation")', // calculation is what save in db and what user needs to put in "dataEntry"
      consolidateHour: 'Consolida por hora',
      consolidateDay: 'Consolida por dia',
      consolidateShift: 'Consolida por turno',
      consolidateWeek: 'Consolida por semana',
      consolidateMonth: 'Consolida por mês',
      conversionInterval: 'Intervalo da conversão (mínimo: 1)',
      tagCode: 'Código da tag',
      tagDescription: 'Descrição da tag',
    },
    goal: {
      title: 'Meta',
      uniqueGoal: 'Meta única',
      lowerGoal: 'Meta inferior',
      upperGoal: 'Meta superior',
      proportional: 'Proporcional',
      alreadyExists: 'Já existe uma meta para esta tag neste período',
    },
    group: {
      validTags: 'Tags válidas',
      activate: 'Ativar grupo',
      unregisteredTags: 'Tags não cadastradas',
      tagsInGroup: 'Tags já existentes no grupo',
      type: 'Tipo do Grupo',
      integration: 'Integração',
      manualCollection: 'Coleta Manual',
      alarmManagement: 'Gestão de Alarmes',
      rootCause: 'Análise de Causa Raiz',
      alreadySelected: 'Todas as tags selecionadas já estão inclusas nessa aba',
      noReferenceValue:
        'Há alterações não salvas ou tags sem valor de alarme inativo',
      noSelectedTags: 'Não há tags selecionadas',
      availableRuleTags: 'Tags disponíveis para definição de regras',
      alarmManagementExplanation:
        'Um alarme será contabilizado quando os valores das tags ativas pertencentes ao grupo forem diferentes dos valores de referência definidos. Tags desativadas não serão consideradas',
      deleteGroupError:
        "Não é possível apagar o grupo pois existem regras associadas. Você pode desativar o grupo clicando em 'editar'",
      activeGroup: 'Grupo ativo',
      report: 'Relatório',
      titleTags: 'Tags',
      disassociateTagError:
        'Não é possível desassociar tags que já possuem alarmes registrados',
      availableTags: 'Tags disponíveis',
      searchTag: 'Pesquisar tag',
      selectedTags: 'Tags selecionadas',
      titleUsers: 'Operadores',
      availableUsers: 'Operadores disponíveis',
      searchUser: 'Pesquisar operador',
      selectedUsers: 'Operadores selecionadas',
      rules: 'Regras',
      selectedTagNotInCalc: 'Tag selecionada não está no cálculo',
      tagsNotInGroup:
        'Há tags no cálculo que não pertencem a esse grupo: {tags}',
      unsupportedLogicalOperators:
        'Há operadores lógicos não suportados no cálculo: {operators}',
      syntaxError: 'Erro de sintaxe',
      validatedCalculation: 'Cálculo validado',
      validSyntax:
        "Validações possíveis (>, <, ==, !=). Ex: 'TAG_REGRA' > ('TAG1' + 'TAG2') / 'TAG3'",
      needAllValidations: 'Todas as regras precisam ser validadas',
      validateExpression: 'Validar a expressão',
      tagsWithoutValue:
        'Há tags no calculo sem valor, por favor realizar o preenchimento: {tags}, cálculo: {calculation}',
      valueNotValid: 'Valor informado não é válido, cálculo: {calculation}',
      consecutivePoints: 'Pontos consecutivos',
      calcTagIsSelected:
        'Não é possível selecionar a opção "Sem agrupamento" para tags do tipo cálculo',
    },
  },
  integration: {
    log: 'Log',
    onlyErrors: 'Mostrar apenas solicitações com erros',
    rerunButton: 'Reexecutar as solicitações',
    executionDate: 'Data de execução',
    executionTime: 'Tempo de execução',
    payload: 'Payload',
    result: 'Resultado',
    triggerType: 'Tipo do gatilho',
    eventGroup: 'Grupo de eventos',
    eventType: 'Tipo do evento',
    defaultEvent: 'Evento predefinido',
    anyEvent: 'Qualquer evento',
    anyTag: 'Qualquer tag',
    anyMatrix: 'Qualquer matriz',
    invalidCronjob: 'Cronjob inválido',
    invalidJson: 'JSON inválido',
    stepOne: 'Etapa #1',
    step: 'Etapa',
    subStep: 'Subetapa',
    integrationSuccessUpdate: 'Integração alterada com sucesso!',
    default: 'Padrão',
    header: 'Cabeçalho',
    back: 'Voltar',
    saveChanges: 'Salve as alterações',
    dataError:
      'Houve um problema ao consultar os dados de integração, por favor, tente novamente',
    deleteConfirmation: 'Tem certeza que deseja excluir esta integração?',
    excludeIntegration: 'Excluir integração',
    editRequests: 'Editar requisições',
    addRequests: 'Adicionar requisições',
    subRequests: 'Sub-requisições',
    loop: 'Repetição',
    delay: 'Atraso',
    get: 'GET',
    post: 'POST',
    put: 'PUT',
    eventTypeTitle: {
      tagsGroup: 'Grupo de Tags',
      approvalArea: 'Área de aprovação',
      anyFrame: 'Qualquer evento',
      runEvery: 'Executar a cada',
      onOpenFrame: 'Ao abrir evento',
      onCloseFrame: 'Ao fechar evento',
      onCreateEffect: 'Ao criar efeito (causa raiz)',
      onCompleteEffect: 'Ao concluir efeito (causa raiz)',
      onInsertAction: 'Ao inserir ação (causa raiz)',
      onCompleteAction: 'Ao concluir ação (causa raiz)',
      onDelayAction: 'Ao atrasar ação (causa raiz)',
      onApproveStatus: 'Ao alterar o status de aprovação (causa raiz)',
      onSendToApproval: 'Ao enviar para para aprovação (causa raiz)',
      onOpenProductionOrder: 'Ao abrir ordem de produção',
      onCloseProductionOrder: 'Ao fechar ordem de produção',
      onReopenProductionOrder: 'Ao reabrir ordem de produção',
      onOperationalSecutitySendToApprove:
        'Ao enviar para para aprovação (segurança operacional)',
      onOperationalSecutityApproveStatus:
        'Ao alterar o status de aprovação (segurança operacional)',
      onOperationalSecutityExtendSolicitation:
        'Ao solicitar ampliação do impairment (segurança operacional)',
      onOperationalSecutityExtendSolicitationApprovalStatus:
        'Ao alterar status da solicitação de ampliação do impairment (segurança operacional)',
      onOperationalSecutityExecution:
        'Ao executar o SCI (segurança operacional)',
      onOperationalSecutityRestoration:
        'Ao restabelecer o instrumento (segurança operacional)',
      onOperationalSecutityRestorationLate:
        'Restabelecimento em atraso (segurança operacional)',
      onOperationalSecutityRoutineTestExecution:
        'Ao realizar teste de rotina (segurança operacional)',
      onOperationalSecutityRoutineTestRepeat:
        'Repetição de teste (segurança operacional)',
      onOperationalSecutityRoutineTestImpairment:
        'Necessidade de impairment (segurança operacional)',
      onOperationalSecutityImpairmentWithoutApprovation:
        'Instrumento desativado sem aprovação (segurança operacional)',
      onOperationalSecutityManualOperating:
        'Operando em manual (segurança operacional)',
      onOperationalSecutityManualInterlock:
        'Intertravamento em manual (segurança operacional)',
      onOperationalSecutityRoutineTestLate:
        'Atraso de teste de rotina (segurança operacional)',
      onManagementOfChangesSendToApprove:
        'Ao enviar para para aprovação (gestão de mudanças)',
      onManagementOfChangesApproveStatus:
        'Ao alterar o status de aprovação (gestão de mudanças)',
      onManagementOfChangesExecution: 'Ao executar o SCI (gestão de mudanças)',
      onManagementOfChangesRestoration:
        'Ao restabelecer o instrumento (gestão de mudanças)',
      onManagementOfChangesRestorationLate:
        'Restabelecimento em atraso (gestão de mudanças)',
      onIntegrationFailure: 'Falha de integração (integração)',
      onCreateUser: 'Ao criar usuário',
      onUpdateUserPassword: 'Ao alterar senha do usuário',
      onClosePRP: 'Ao finalizar PRP (manutenção integrada)',
    },
    title: {
      cronjob: 'Cronjob',
      rootCauseCreateEffect: 'Causa raiz - criar efeito',
      rootCauseEndEffect: 'Causa raiz - finalizar efeito',
      rootCauseInsertAction: 'Causa raiz - inserir ação',
      rootCauseConcludeAction: 'Causa raiz - concluir ação',
      rootCauseDelayAction: 'Causa raiz - atrasar ação',
      rootCauseSendToApprove: 'Causa raiz - enviar para aprovação',
      rootCauseApprovalStatus: 'Causa raiz - alterar status de aprovação',
      startEvent: 'Iniciar evento',
      endEvent: 'Encerrar evento',
      openProductionOrder: 'Abrir ordem de produção',
      closeProductionOrder: 'Fechar ordem de produção',
      reopenProductionOrder: 'Reabrir ordem de produção',
      manualCollect: 'Inserção de dados',
      manualCollectCsv: 'Inserção de dados via CSV',
      manualCollectEdit: 'Edição de dados',
      manualCollectDelete: 'Exclusão de dados',
      operationalSecutitySendToApprove: 'SCI - Enviar para aprovação',
      operationalSecutityApprovalStatus: 'SCI - Alterar status de aprovação',
      operationalSecutityExtendSolicitation:
        'SCI - Solicitação de ampliação do impairment',
      operationalSecutityExtendSolicitationApprovalStatus:
        'SCI - Alterar status da solicitação de ampliação do impairment',
      operationalSecutityExecution: 'SCI - Execução',
      operationalSecutityRestoration: 'SCI - Restabelecimento',
      operationalSecutityRestorationLate: 'SCI - Restabelecimento em atraso',
      operationalSecutityRoutineTestExecution: 'SCI - Teste de rotina',
      operationalSecutityRoutineTestRepeat: 'SCI - Repetição de teste',
      operationalSecutityRoutineTestImpairment:
        'SCI - Necessidade de impairment ',
      operationalSecutityImpairmentWithoutApprovation:
        'SCI - Instrumento desativado sem aprovação',
      operationalSecutityManualOperating: 'SCI - Operando em manual',
      operationalSecutityManualInterlock: 'SCI - Intertravamento em manual',
      operationalSecutityRoutineTestLate: 'SCI - Atraso de teste de rotina',
      managementOfChangesSendToApprove: 'MOC - Enviar para aprovação',
      managementOfChangesApprovalStatus: 'MOC - Alterar status de aprovação',
      managementOfChangesExecution: 'MOC - Execução',
      managementOfChangesRestoration: 'MOC - Restabelecimento',
      managementOfChangesRestorationLate: 'MOC - Restabelecimento em atraso',
      integration: 'Integração',
      integrationFailure: 'Integração - Falha de integração',
      createUser: 'Criação de usuário',
      updatePassword: 'Alteração de senha do usuário',
    },
    variables: {
      effectOne: 'Efeito T #1',
      effectOneCommentary: 'Este efeito foi finalizado em 2010-02-25',
      actionOne: 'Ação A #1',
      actionOneWhy: 'Porque da ação A #1',
      actionOneHow: 'Como da ação A #1',
      actionOneWhat: 'O que da ação A #1',
      actionOneCommentary: 'Comentário da ação A #1',
      eventTitle: 'Título do evento',
      eventCode: 'Código do evento',
      productionOrderCode: 'codigo#123',
      productionOrderStatus: 'status',
      productionOrderExternalCode: 'codigo-externo#123',
      productionOrderItemCode: 'codigo-item',
      productionOrderExternalItemCode: 'codigo-externo-item',
      tagCode: 'TAG_CODIGO',
      sciInstrumentDesc: 'Descrição do instrumento',
      mocDesc: 'Nome da mudança',
      genericText:
        'Lorem ipsum dolor sit amet. Et eligendi pariatur sed deserunt quae eum nostrum galisum et eveniet nulla aut omnis voluptas.',
      integrationTitle: 'Título da integração',
      eventType: 'Tipo de evento',
      step: 'Etapa #1',
      endpoint: 'http://localhost:9000/endpoint',
      payload: "{'name': 'joao', senha: 'senha123'}",
      response: "{'error':{'code': 400}}",
    },
    parameters: {
      errorCreateNew: 'Erro ao criar um novo parâmetro de integração',
    },
    laboratory: {
      integrationType: 'Tipo integração',
      pimsLab: 'Digitação de laboratório',
      pimsWrite: 'Fechamento boletim - escrita',
      pimsRead: 'Fechamento boletim - leitura',
      pmsIntegrationType: 'PMS - Balanço de Massa e Energia',
      pmsMassRead: 'Balanço de massa e energia - leitura',
      pmsMassWrite: 'Balanço de massa e energia - escrita',
      pmsMassAccomplished: 'Balanço de massa e energia - realizado',
      read: 'Leitura',
      write: 'Escrita',
      accomplished: 'Realizado',
      originVariable: 'Variável de origem',
      destinyVariable: 'Variável de destino',
      hourVariable: 'Variável hora',
      ruleCollect: 'Regra coleta',
      ruleZero: 'Regra 0',
      ruleZeroDescription: 'Hora na linha do topo da tela do PIMS',
      ruleOne: 'Regra 1',
      ruleOneDescription:
        'Digita a hora em outra variável do PIMS sem a subfonte (definir esta váriavel no Tag)',
      ruleTwo: 'Regra 2',
      ruleTwoDescription: '3 digitações por dia (1 por turno)',
      ruleThree: 'Regra 3',
      ruleThreeDescription: '6 digitações por dia (2 por turno)',
      ruleFour: 'Regra 4',
      ruleFourDescription:
        'Primeira hora do dia incrementando a cada 1 hora (24 digitações no dia)',
      ruleFive: 'Regra 5',
      ruleFiveDescription: 'Retorna a média calculada na última coluna do PIMS',
      ruleSix: 'Regra 6',
      ruleSixDescription:
        'Converte um texto digitado no PIMS para um valor numérico a partir do cadastro feito nas variáveis globais',
      typeNotFound: 'Tipo não encontrado',
      type: 'Tipo',
    },
  },
  integrationSystem: {
    production: 'Produção e Processo',
    enableApprove: 'Habilitar aprovação',
    enableInsertAppointment: 'Habilitar inserção de apontamento',
    tagsGroup: 'Grupo de Tags',
    showDayAggregation: 'Exibir agregação por dia',
    showShiftAggregation: 'Exibir agregação por turno',
  },
  manualCollect: {
    appointmentsQuantity: 'Quantidade de apontamentos',
    startHour: 'Hora inicial',
    type: 'Tipo',
    createNewItem: 'Criar um novo item',
    tagGroup: 'Grupo de tag',
    onlyShowManualInput: 'Só serão exibidas as tags do tipo entrada manual',
    notShowButton: 'O botão de adicionar novos horários não será exibido',
    showEndTime: 'Exibir horário final',
    alreadyAssociated: 'Grupo de tag já associado a uma coleta',
    group: {
      permissions: 'Permissões',
      userPermissions: 'Permissão para usuários',
    },
  },
  oee: {
    title: 'OEE',
    available: 'Disponibilidade',
    quality: 'Qualidade',
    performance: 'Desempenho',
    dashboard: 'Dashboard',
    dashboardNotFound: 'Dashboard não encontrado',
    dailyValues: 'Valores diários',
    resume: 'Resumo',
    back: 'Voltar',
    next: 'Próximo',
    instructions: 'Instruções',
    availableFirstParagraph:
      'Se o tempo de parada já for calculado pela automação, selecione a tag a ser coletada. Caso contrário, selecione ou cadastre um novo evento',
    availableSecondParagraph:
      'Observação: se um novo evento for criado, é necessário configurar ou apontar o motivo da parada manualmente',
    availableTag: 'Tag de disponibilidade',
    tagDescription: 'Tag descrição',
    tagCode: 'Tag código',
    availableOptionOne: 'Opção 1 - via tag cadastrada',
    availableOptionTwo: 'Opção 2 - via nova tag',
    availableOptionThree: 'Opção 3 - via evento',
    stopsEvent: 'Evento de paradas',
    createNewFrame: 'Criar novo evento',
    dashboardParagraph:
      'Defina como o OEE será exibido dentro de dashboard no menu e o título da tab. É possível também adicionar um ícone para melhor identifica-los',
    viewTitle: 'Título da visão',
    tabTitle: 'Título tab',
    descriptionParagraph:
      "Insira um titulo de descrição para o OEE a ser criado. Este titulo será exibido na tabela de OEE's criados",
    oeeTitle: 'Título do OEE',
    oeeStepParagraph:
      "Insira um título de descrição para o OEE a ser criado. Este título será exibido na tabela de OEE's criados",
    tagCalculation: 'Tag cálculo',
    performanceParagraph:
      'Aponte a performance do equipamento, definindo a quantidade de peças produzidas por ciclo e defina se o tempo de ciclo será obtido através de uma tag ou se será um tempo de ciclo fixo',
    tagQuality: 'Tag de qualidade',
    tagPerformance: 'Tag de desempenho',
    ciclePieces: 'Peças por ciclo',
    cicleTime: 'Tempo do ciclo - tag',
    performanceOptionThree: 'Opção 3 - via tempo fixo',
    fixedCicleTime: 'Tempo do ciclo - fixo',
    inSeconds: 'Em segundos',
    oeeAvailable: 'OEE - Disponibilidade',
    oeePerformance: 'OEE - Desempenho',
    oeeQuality: 'OEE - Qualidade',
    oeeGoodProduction: 'OEE - Produção boa',
    oeeBadProduction: 'OEE - Produção ruim',
    oeeTotalProduction: 'OEE - Produção total',
    qualityParagraph:
      'Selecione as tags que correspondem a cada indicador. Para os campos que ficarem em branco, o sistema preencherá com uma tag gerada automaticamente. Selecione a tag que deverá ser calculada pelo sistema, sendo que as não selecionadas serão consideradas como valores a serem coletados',
    goodProduction: 'Produção boa',
    calculation: 'Cálculo',
    manualCollectScreen: 'Tela de coleta manual',
    createNewItem: 'Criar um novo item',
    badProduction: 'Produção defeituosa',
    totalProduction: 'Produção total',
    resumeFirstParagraph:
      'Informações da tag que representará a qualidade da produção no dashboard:',
    resumeSecondParagraph:
      'Informações da tag que representará a performance do equipamento no dashboard:',
    resumeThirdParagraph:
      'Informações da tag que representará a disponibilidade do equipamento no dashboard:',
    resumeFourthParagraph:
      'Informações da tag que representará o resultado da produção, apresentando o produto da multiplicação das três tags apresentadas anteriormente',
    resumeFifthParagraph:
      'Um novo dashboard será criado no menu “Dashboard”, lá será possível visualizar a resultante dos cálculos efetuados pelo sistema e apresentados através de gráfico tipo gauge. Os valores resultantes serão apresentados através das tags descritas neste resumo',
    type: 'Tipo',
    selected: 'Selecionado',
    report: {
      externalCloseStatus: 'Status de fechamento externo',
      internalCloseStatus: 'Status de fechamento interno',
    },
  },
  token: {
    title: 'Token',
    successCreate: 'Token criado com sucesso',
    showOnce:
      'Este token será exibido apenas uma vez, armazene em um lugar seguro',
  },
  alarmManagement: {
    begin: 'Início do alarme',
    group: 'Área do alarme',
    duration: 'Duração do alarme',
    report: {
      selectGroup: 'Selecione os grupos de tags para gerar o gráfico',
      totalAlarmsByPeriod: 'Total de alarmes no período',
      numberOfAlarmsByGroup: 'Número de alarmes por grupo',
      mostRecurringAlarms: 'Dez alarmes mais recorrentes',
      listOfAlarmsByPeriod: 'Lista de alarmes',
    },
  },
  integratedMaintenance: {
    annualWeek:
      '*As semanas são baseadas nas semanas do ano e não na configuração de início da semana na plataforma',
    lastAnnualWeek:
      '**As O.S. que ultrapassam a 52ª semana são contabilizadas como pertencentes à 52ª semana',
    weekCalendarProgrammed: 'Programadas',
    weekCalendarAccomplished: 'Realizadas',
    weekCalendarPercentage: 'Percentual',
    weekCalendar: 'Calendário semanal - O.S. preventivas',
    plan: 'Plano',
    annualMap: 'Mapa anual de O.S. preventivas',
    dateConfigError:
      'Não há turnos e horários configurados, por favor configure para que o relatório possa ser gerado',
    materialsLoading:
      'A busca de materiais pode demorar um pouco, por favor aguarde...',
    materialsError:
      'Não foi encontrado materiais com esse código, por favor tente novamente...',
    typeMaterial: 'Digite o código ou descrição do material para fazer a busca',
    serviceOrders: 'Ordens de serviço',
    from: 'De',
    cantEditPrp: 'Não é possível editar uma O.S. do tipo PRP',
    filterPerformers: 'Filtrar executantes',
    filterBacklog: 'Filtrar pendências',
    cannotMovePrp:
      'Não é possível mover uma O.S. do tipo PRP para as pendências',
    programPerformers: 'Programar executantes',
    performersHelp: 'Necessário selecionar {quantityResources} executantes',
    differentUserGroup:
      'Não foi possível atribuir O.S. a este usuário, pois o mesmo não pertence ao grupo de recursos desta rota',
    performers: 'Executantes',
    backlog: 'Pendências',
    programming: 'Programação',
    accomplished: 'Realizado',
    programmed: 'Programado',
    weekAndMonth:
      'Para semanas serão considerados 7 dias, e, para meses, 30 dias',
    day: 'dia(s)',
    week: 'semana(s)',
    month: 'mes(es)',
    year: 'ano(s)',
    osGenerated: 'O.S. gerada:',
    serviceOrderType: 'Tipo de ordem de serviço',
    observationNeeded: 'Em caso de reprovação, a observação é obrigatória',
    approveReprove: 'Tem certeza que deseja aprovar/reprovar essa O.S.?',
    initialStatus: 'Inicial',
    inProgressStatus: 'Em andamento',
    waitingApprovalStatus: 'Aguardando aprovação',
    finishedStatus: 'Finalizada',
    approvedStatus: 'Aprovada',
    reprovedStatus: 'Reprovada',
    verification: 'Verificação',
    blockServiceOrder: 'Bloquear O.S.',
    initialServiceOrderStatus: 'Status inicial da O.S.',
    title: 'Manutenção integrada',
    quantitativeVerification: 'Verificação quantitativa',
    qualitativeVerification: 'Verificação qualitativa',
    lubricationGrease: 'Lubrificação/engraxamento - quantitativo',
    lubricationExecution: 'Lubrificação/engraxamento - execução',
    procedureExecution: 'Execução de procedimento',
    materialType: 'Tipo de material',
    piece: 'Peça',
    lubricant: 'Lubrificante',
    externalService: 'Serviço externo',
    lastPurchasePrice: 'Custo unitário',
    quantityStock: 'Quantidade estoque',
    quantityReserved: 'Quantidade reservada',
    quantityReservedService: 'Quantidade reservada serviço',
    price: 'Preço',
    serviceType: 'Tipo do serviço',
    preventiveModality: 'Modalidade preventiva',
    routineItems: 'Itens de rotina',
    planning: 'Planejamento',
    schedule: 'Programação',
    attachments: 'Anexos',
    resourceQuantity: 'Quantidade de recursos',
    previousDays: 'Nº de dias para antecipar a geração da O.S.',
    differentResources:
      'Quantidade de recursos diferente da quantidade de executantes selecionados',
    elementTag: 'Elemento / Tag',
    service: 'Serviço',
    setFrequencyOnRoute: 'Definir frequência e tempo previsto na rota',
    frequency: 'Frequência',
    expectedTime: 'Tempo previsto',
    expectedTimeMin: 'Tempo previsto (min)',
    element: 'Elemento',
    elements: 'Elementos',
    material: 'Material',
    maxLimit: 'Limite máximo',
    minLimit: 'Limite mínimo',
    okNotOk: 'Conforme/não conforme',
    executedNotExecuted: 'Procedimento executado/procedimento não executado',
    prpCreated: 'PRP criada com sucesso!',
    selectOperator: 'Selecione o operador responsável',
    selectShift: 'Selecione o turno',
    selectOrder: 'Selecione a ordem',
    createPrp: 'Criar PRP',
    onClosePRP: 'Ao finalizar PRP',
    seeCompleteOs: 'Ver O.S. completa',
    executed: 'Executado',
    notExecuted: 'Não executado',
    ok: 'Conforme',
    notOk: 'Não conforme',
    to: 'a',
    os: 'O.S.',
    requestDate: 'Data de solicitação',
    conclusionDate: 'Data de conclusão',
    actualStartDate: 'Data de início real',
    actualEndDate: 'Data de fim real',
    status: 'Status',
    verificationStatus: 'Status de verificação',
    noOsToShow: 'Sem O.S. para exibir',
    prp: 'Preventiva e preditiva',
    execution: 'Execução',
    obtained: 'Obtido',
    osByPrp: 'O.S. gerada pela PRP',
    mcu: 'Manutenção corretiva urgente',
    mcp: 'Manutenção corretiva programada',
    nm: 'Nota de manutenção',
    prpUc: 'PRP',
    mcuUc: 'MCU',
    mcpUc: 'MCP',
    planned: 'Planejadas',
    performed: 'Realizadas',
    hoursUsed: 'Horas gastas',
    inspectionRoutesHoursUsed: 'Rotas de inspeção x horas gastas',
    plannedExecuted: 'Planejado x realizado',
    requester: 'Solicitante',
    responsable: 'Responsável',
    route: 'Rota',
    responsableOperator: 'Responsável',
    approvedBy: 'Aprovado por',
    disapprovedBy: 'Reprovado por',
    approvedAt: 'Aprovado em',
    disapprovedAt: 'Reprovado em',
    approved: 'Aprovado',
    disapproved: 'Reprovado',
    maintenanceActivity: 'Atividade de manutenção',
    priority: 'Prioridade',
    searchOperator: 'Pesquisar por operador',
    serviceOrder: 'Ordem de serviço',
    osType: 'Tipo OS',
    emission: 'Emissão',
    criticality: 'Criticidade',
    materialsUsed: 'Materiais utilizados',
    materialsForeseen: 'Materiais previstos',
    materialForeseen: 'Material previsto',
    quantityUsed: 'Quantidade utilizada',
    quantityForeseen: 'Quantidade prevista',
    operator: 'Operador',
    urgent: 'Urgente',
    moderate: 'Moderada',
    itemsRoute: 'Itens da rota',
    obtainedInInspection: 'Obtido na inspeção',
    stopped: 'Parado',
    stoppedEquipment: 'Equipamento parado',
    observation: 'Observação',
    osItems: 'Serviços da O.S.',
    executionDate: 'Data de execução',
    performer: 'Executante',
    serviceOrderFinished: 'Ordem de serviço concluída',
    serviceOrderCanceled: 'Ordem de serviço cancelada',
    serviceOrderOpen: 'Ordem de serviço aberta',
    wasFinished: 'Está ordem de serviço foi concluída',
    wasCanceled: 'Está ordem de serviço foi cancelada',
    clickToReopen: 'Clique aqui para reabri-lá',
    attachmentSuccess: 'Arquivo anexado com sucesso',
    attachmentDeleteError: 'Erro ao tentar excluir anexo da ordem de serviço',
    finishServiceOrder: 'Concluir ordem de serviço',
    cancelServiceOrder: 'Cancelar ordem de serviço',
    clickToAddAttachment: 'Clique para adicionar anexo',
    situation: 'Situação',
    cost: 'Custo',
    serviceCost: 'Custo do serviço',
    generateErpRequisition: 'Gerar requisição ERP',
    typeCurrentStatus: 'Digite o status atual',
    typeDetails: 'Digite as observações',
    generateOs: 'Gerar O.S.',
    sendApproval: 'Enviar para aprovação',
    generateNote: 'Gerar nota',
    startTime: 'Hora inicial',
    endTime: 'Hora final',
    hoursPlanned: 'Horas planejadas',
    plannedStartTime: 'Data de início planejada',
    realStartTime: 'Data de início real',
    plannedEndTime: 'Data de fim planejada',
    realEndTime: 'Data de fim real',
    osCreationDate: 'Data de criação da O.S.',
    serviceCreationDate: 'Data de criação do serviço',
    addService: 'Adicionar serviço',
    labor: 'Mão de obra',
    center: 'Centro',
    deposit: 'Depósito',
    reservationNumber: 'Nº reserva',
    attendanceNumber: 'Nº atendimento',
    estimatedCost: 'Custo estimado',
    realCost: 'Custo real',
    lastAppointment: 'Último apontamento',
    serviceDescription: 'Descrição do serviço',
    hoursAppointed: 'Horas apontadas',
    appointLabor: 'Apontar mão de obra',
    lastAppointmentInformed: 'Último apontamento já informado',
    isActive: 'Ativo',
    expectedQuantity: 'Quantidade prevista',
    amountUsed: 'Quantidade utilizada',
    serviceCode: 'Código do serviço',
    resourceGroup: 'Grupo de recursos',
    numberOfOperators: 'Quantidade de operadores',
    realStartAndEnd: 'Data de início / Fim real',
    elementAndEquipment: 'Elemento / Equipamento',
    futureHoursNotAllowed: 'Não é permitido o apontamento de horas futuras',
    betweenDaysNotAllowed: 'Não é permitido o apontamento entre dias distintos',
    betweenServiceOrdersNotAllowed:
      'Não é permitido o apontamento entre serviços distintos, O.S.: {serviceOrder}, serviço: {service}',
    betweenServiceOrdersNotAllowedWithoutService:
      'Não é permitido o apontamento entre serviços distintos, O.S.: {serviceOrder}',
    startTimeEqualEndTime:
      'Não é permitido o apontamento de hora inicial igual a hora final',
    maxTimeUninterruptedNotAllowed:
      'Não é permitido o apontamento maior do que {time} ininterruptas',
    maxTimeDayNotAllowed:
      'Não é permitido o apontamento maior do que {time} diárias',
    maxTimeDayNotAllowedServiceOrder:
      'Não é permitido o apontamento maior do que {time} diárias, O.S.: {serviceOrder}, serviço: {service}',
    maxTimeDayNotAllowedServiceOrderWithoutService:
      'Não é permitido o apontamento maior do que {time} diárias, O.S.: {serviceOrder}',
    minBreakTimeDayNotAllowed:
      'Não é permitido o apontamento menor do que {time} entre jornadas',
    historic: 'Movimentação de equipamento',
    sourceElement: 'Elemento origem',
    destinationElement: 'Elemento destino',
    movementDate: 'Data de movimentação',
    moveEquipment: 'Movimentar equipamento',
    substituteEquipment: 'Equipamento substituto',
    associatedEquipment:
      'Este equipamento possui serviços de manutenção preventiva associados a ele, informar equipamento substituto',
    sequenceAlreadyExists: 'Sequência já existente',
    sourceServiceOrder: 'Código da O.S. de origem',
    standardMap: 'Mapa padrão',
    satteliteMap: 'Mapa satélite',
    rules: {
      maxTimeUninterrupted: 'Tempo máximo ininterrupto',
      maxTimeDay: 'Tempo máximo diário',
      minBreakTimeDay: 'Intervalo mínimo entre jornadas',
      appointFutureHours: 'Verificar apontamento de horas futuras',
      appointBetweenDays: 'Verificar apontamento entre dias distintos',
      appointBetweenServiceOrders:
        'Verificar apontamento entre serviços distintos',
      automaticRegistrationApp:
        'Registro automático de mão de obra via aplicativo',
    },
    integrationStatus: {
      label: 'Status de integração',
      waiting: 'Aguardando',
      success: 'Sucesso',
      error: 'Erro',
    },
    report: {
      numberOfOrdersPerDay: 'Número de ordens programadas X realizadas',
      numberOfOrdersPerActivityType: 'Número de ordens X tipo de ordem',
      hourPerElementPerAcitivityType: 'Alocação de horas X grupo de recursos',
      numberOfOrdersPerExecutionStatus: 'Número de ordens X status de execução',
      numberPerElementPerStatusByDay: 'Status X período X grupo de recursos',
      percentOfOrdersPerUserGroups: 'Percentual de ordens X grupo de recursos',
      numberOfOrdersPerUserGroups: 'Número de ordens X grupo de recursos',
      costForeseenPerOsType:
        'M.O planejada X materiais planejados X tipo de ordem',
      costForeseenPerElement: 'M.O planejada X materiais planejados X elemento',
      costPerOsType: 'M.O utilizada X materiais utilizados X tipo de ordem',
      costPerElement: 'M.O utilizada X materiais utilizados X elemento',
      numberOfOrderPerElement: 'Número de ordens X elemento',
      numberOfOrdersPerElementPerActivityType:
        'Número de ordens X tipo de ordem X elemento',
      selectReport: 'Selecione um relatório para gerar o gráfico',
      numberOfHours: 'Número de horas',
      numberOfOrders: 'Número de ordens',
      withoutStatus: 'Sem status',
      laborForeseen: 'M.O planejada',
      materialsForeseen: 'Materiais planejados',
      laborUsed: 'M.O utilizada',
      materialsUsed: 'Materiais utilizados',
      monday: 'Segunda',
      tuesday: 'Terça',
      wednesday: 'Quarta',
      thursday: 'Quinta',
      friday: 'Sexta',
      saturday: 'Sábado',
      sunday: 'Domingo',
      january: 'Janeiro',
      february: 'Fevereiro',
      march: 'Março',
      april: 'Abril',
      may: 'Maio',
      june: 'Junho',
      july: 'Julho',
      august: 'Agosto',
      september: 'Setembro',
      october: 'Outubro',
      november: 'Novembro',
      december: 'Dezembro',
      programmedAccomplished:
        'Programadas: {total} - Realizadas: {completed} - Percentual: {percentage}%',
      total: 'Total: {total}',
      programmed: 'Total',
      accomplished: 'Realizadas',
    },
  },
  pdfTemplate: {
    headerImage: 'Imagem do cabeçalho',
    footerImage: 'Imagem do rodapé',
    templates: 'Templates',
  },
  dataExport: {
    horizontalTag: 'Horizontal - Tag',
    horizontalDate: 'Horizontal - Data',
  },
  barcodeLabel: {
    content: 'Conteúdo do Código',
    contentHelp: 'Use o leitor para usar um conteúdo predefinido',
    module: 'Módulo',
    QRCodeDynamic: 'Código QR Dinâmico',
    addStep: 'Adicionar Etapa',
    payload_id: 'Cadastro',
    payload_value: 'Valor',
  },
}

export default localeValues
